import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Icon from "../Helpers/Icon";
import { getImageUrl } from "../Helpers/Constants";
import Button from "./Core/Button";
import Attachment from "./Attachment";
import CreateCounter from "./CreateCounter";
import Modal from "@mui/material/Modal";
import { TextWrap } from "react-huge-icons/outline";

const moment = require("moment");

const PropertyCard = ({
  isShow,
  type,
  data,
  onSubmit,
  errors,
  handleChange,
  counterData,
  selectedIds,
  setSelectedIds,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);



  const handleCheckboxChange = (event) => {
    const id = event.target.value;
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((item) => item !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        // flexWrap: "wrap",
        height: "80px",
        width: "97%",
        background: "#F8F8F8 0% 0% no-repeat padding-box",
        borderRadius: "12px",
        // marginRight: "20px",
        marginBottom: "16px",
        paddingLeft: "21px",
        paddingRight: "24px",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          height: "2px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgb(44, 128, 255)",
          borderRadius: "10px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        {type === "newOrders" && (
          <input
            type="checkbox"
            value={data._id}
            onChange={handleCheckboxChange}
          />
        )}

        <img
          alt="images"
          src={
            data?.images && data?.images.length ? data?.images[0] : data?.images
          }
          style={{
            width: "90px",
            height: "60px",
            borderRadius: "10px",
            marginRight: "10px",
          }}
        />

        {(type === "Property" ||
          type === "assigned" ||
          type === "newOrders" ||
          type === "Assigned") && (
          <Box
            sx={{
              color: "#000000",
              fontSize: "12px",
              font: "normal normal normal 11px/12px Poppins",
              marginLeft: "10px",
              whiteSpace: "nowrap",
              marginTop: "5px",
              width: "13rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display:"flex",
              justifyContent:"flex-start",
              marginRight:"10px"
            }}
          >
            {data?.address},{data?.state} ,{data?.city},{data?.zipcode}
          </Box>
        )}

        {(type === "Orders" ||
          type === "assigned" ||
          type === "newOrders" ||
          type === "Assigned") && (
          <Box
            sx={{
              width: "16rem",

              color: "#000000",
              fontSize: "11px",
              font: "normal normal normal 11px/19px Poppins",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Icon
              name="Order"
              style={{
                marginRight: "4px",
              }}
            />
            Order ID: #{data?._id}
          </Box>
        )}
      </Box>

      {type !== "assigned" && type !== "newOrders" && type !== "Assigned" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // width: "40rem",
            borderRadius: "5px",
          }}
        >
          <Box
            className="iconContainer"
            sx={{
              display: "flex",
              width: "17rem",
            }}
          >
            <Icon
              name="Grid1"
              style={{
                // width: "17px",
                height: "17px",
                marginRight: "4px",
              }}
            />

            <Box
              sx={{
                color: "#000000",
                fontSize: "11px",
                font: "normal normal normal 11px/19px Poppins",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Property ID: #{data?._id}
            </Box>
          </Box>

          <Box
            className="iconContainer"
            sx={{ width: "6rem", display: "flex" }}
          >
            <Icon
              name="Grid2"
              sx={{
                marginTop: "4px",
                width: "17px",
                height: "17px",
                marginRight: "10px",
              }}
            />

            <Box
              sx={{
                color: "#000000",
                fontSize: "11px",
                font: "normal normal normal 11px/19px Poppins",
                whiteSpace: "nowrap",
                marginLeft: "7px",
              }}
            >
              {moment(data?.createdAt).format("DD/MM/YY")}
            </Box>
          </Box>

          <Box
            className="iconContainer"
            style={{ width: "7rem", display: "flex" }}
          >
            <Icon
              name="Grid3"
              style={{
                marginTop: "4px",
                width: "17px",
                height: "17px",
                marginRight: "15px",
              }}
            />

            <span
              style={{
                color: "#000000",
                fontSize: "11px",
                font: "normal normal normal 11px/19px Poppins",
                whiteSpace: "nowrap",
              }}
            >
              {data?.user?.firstName} {data?.user?.lastName}
            </span>
          </Box>
        </Box>
      ) : (
        ""
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "5px",
        }}
      >
        <Box
          className="iconContainer"
          sx={{
            display: "flex",
            width: "8rem",
          }}
        >
          <Icon
            name="Grid1"
            style={{
              height: "17px",
              marginRight: "4px",
            }}
          />

          <Box
            sx={{
              color: "#000000",
              fontSize: "11px",
              font: "normal normal normal 11px/19px Poppins",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {type === "assigned" || type === "newOrders" || type === "Assigned"
              ? data?.property_id?.[0]?.Propertytype
              : type === "Orders"
              ? data?.type
              : data?.Propertytype}
          </Box>
        </Box>
      </Box>

      {type == "assigned" || type == "newOrders" || type == "Assigned" ? (
        <>
          <Box
            className="iconContainer"
            style={{ width: "8rem", display: "flex" }}
          >
            <Icon
              name="Price"
              sx={{
                marginTop: "4px",
                width: "17px",
                height: "17px",
                marginRight: "15px",
              }}
            />

            <Box
              sx={{
                color: "#000000",
                fontSize: "11px",
                font: "normal normal normal 11px/19px Poppins",
                whiteSpace: "nowrap",
                marginLeft: "10px",
              }}
            ></Box>
          </Box>

          <Box
            className="iconContainer"
            style={{ width: "8rem", display: "flex" }}
          >
            <Icon
              name="Grid2"
              sx={{
                marginTop: "4px",
                width: "17px",
                height: "17px",
                marginRight: "15px",
              }}
            />

            <Box
              sx={{
                color: "#000000",
                fontSize: "11px",
                font: "normal normal normal 11px/19px Poppins",
                whiteSpace: "nowrap",
                marginLeft: "4px",
              }}
            >
              {moment(data?.createdAt).format("DD/MM/YY")}
            </Box>
          </Box>
        </>
      ) : (
        ""
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "10rem",
          borderRadius: "5px",
        }}
      >
        {type == "assigned" && (
          <Box
            className="iconContainer"
            sx={{
              position: "relative",
              left: "7em",
            }}
          >
            <Button
              style={{
                height: "34px",
                backgroundColor: "#EF2B2C",
                width: "6em",
                // marginTop: "auto",
                // marginBottom: "14px",
                marginLeft: "1em",
                padding: "0.3em",
              }}
              // onClick={() => rejectUser(data[0]._id)}
            >
              Revise Order
            </Button>
          </Box>
        )}

        {type === "Property" && (
          <Box
            className="iconContainer"
            style={{ width: "10rem", display: "flex" }}
            onClick={handleOpen}
          >
            <Icon
              name="Attachment"
              style={{
                marginTop: "4px",
                width: "14px",
                height: "15px",
                marginRight: "10px",
              }}
            />

            <span
              style={{
                color: "#2C80FF",
                fontSize: "11px",
                font: "normal normal normal 11px/19px Poppins",
                whiteSpace: "nowrap",
              }}
            >
              View Attachment
            </span>
          </Box>
        )}

        {type === "Orders" && (
          <Box className="iconContainer">
            <Button
              onClick={handleOpen}
              icon="Modify"
              style={{
                marginLeft: "10px",
              }}
            >
              Modify Order
            </Button>
          </Box>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mb: "1em",
              width: "88%",
              margin: "auto",
              marginTop: "5em",
              border: "none",
              outline: "none",
            }}
          >
            {type === "Property" && (
              <Attachment
                images={data.images}
                documents={data.documents}
                handleClose={() => handleClose()}
              />
            )}

            {type === "Orders" && (
              <CreateCounter
                errors={errors}
                handleClose={handleClose}
                onSubmit={() => onSubmit()}
                handleChange={(data, value) => handleChange(data, value)}
                data={counterData}
              />
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default PropertyCard;
