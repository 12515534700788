import React from "react";
import "./index.css";
import { Box } from "@mui/material";

const Input = ({
  type = "text",
  label,
  icon,
  placeholder,
  value,
  onChange,
  error,
  style,
  name,
  isMultiline,
  ...props
}) => {
  return (
    <Box sx={style}>
      {label && (
        <label
          style={{
            color: "#656A72",
            fontSize: "13px",
            fontFamily: "Poppins",
          }}
          htmlFor={"input"}
        >
          {label}
        </label>
      )}
      <Box
        sx={{
          width: "auto",
          background: props.isTransparent
            ? "transparent"
            : "rgba(242, 242, 242, 1) 0% 0% no-repeat padding-box",
          padding: "0 14px",
          height: style?.height || "40px",
          borderRadius: "11px",
          display: "flex",
          flexDirection: "row",
          border: props.isTransparent ? "1px solid #CCCCCC" : "none",
        }}
      >
        {icon && (
          <div
            style={{
              margin: "auto 8px auto 0",
              width: "18px",
              height: "18px",
            }}
          >
            {icon}
          </div>
        )}
        {isMultiline ? (
          <textarea
            rows={20}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              color: "rgba(101, 106, 114, 1)",
              font: "normal normal medium 15px/21px Poppins",
              fontWeight: "500",
              width: "100%",
              resize: "none",
              height: "200px",
              padding: ".5em 0",
              background: "transparent",
            }}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            name={name}
            {...props}
          />
        ) : (
          <input
            id={"input"}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              color: "rgba(101, 106, 114, 1)",
              font: "normal normal medium 14px/21px Poppins",
              fontWeight: "500",
              width: "100%",
            }}
            {...props}
          />
        )}
      </Box>
      {error && (
        <p
          style={{
            color: "orangered",
            fontSize: ".7em",
          }}
          className="error"
        >
          {error}
        </p>
      )}
    </Box>
  );
};

export default Input;
