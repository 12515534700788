import axios from "../Utils/axios";
import { orderConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

export const modifyOrderFee = (body, id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.MODIFY_ORDER_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.modifyOrder}${id}`, body);

            if (res.status === 200) {
                dispatch({
                    type: orderConstants.MODIFY_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: orderConstants.MODIFY_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch order'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching order:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getAssignedOrders = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.MODIFY_ORDER_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getAssignedOrders}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: orderConstants.ASSIGNED_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        orders: res.data.orders,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: orderConstants.ASSIGNED_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch order'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching order:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getCompletedOrders = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.COMPLETED_ORDER_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getCompletedOrders}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: orderConstants.COMPLETED_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        orders: res.data.orders,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: orderConstants.COMPLETED_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch order'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching order:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getRevisionOrders = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.REVISION_ORDER_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getRevisionOrders}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: orderConstants.REVISION_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        orders: res.data.orders,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: orderConstants.REVISION_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch order'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching order:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getCancelledOrders = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.CANCELLED_ORDER_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getCancelledOrders}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: orderConstants.CANCELLED_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        orders: res.data.orders,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: orderConstants.CANCELLED_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch order'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching order:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};