import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import "./index.css";
import Icon from "../Helpers/Icon";
import { useNavigate } from "react-router-dom";
import chatSupport from "../Assets/Images/Mask Group 1@2x.png";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';


const menu = [{
    title: "Dashboard",
    icon: "Home",
    selectedIcon: "HomeSelected",
    selected: true,
    route: "/dashboard"
},
{
    title: "User Management",
    icon: "UserManagement",
    selectedIcon: "UserManagementSelected",
    selected: false,
    route: "/user-management"
},
{
    title: "Admin Management",
    icon: "UserManagement",
    selectedIcon: "UserManagementSelected",
    selected: false,
    route: "/admin-management"
},
{
    title: "Accounting",
    icon: "Accounting",
    selectedIcon: "AccountingSelected",
    selected: false,
    route: "/accounting"
}, {
    title: "Messages",
    icon: "Message",
    selectedIcon: "MessageSelected",
    selected: false,
    route: "/message"
}];

const Sidebar = ({ page = "Dashboard" }) => {
    const navigation = useNavigate();
    const auth = useSelector((state) => state.auth);

    const switchPage = (route) => {
        navigation(route);
    }

    const theme = useTheme();


    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                height: "83vh",
                background: "linear-gradient(180deg, #2593D6 0%, #134A6B 100%)",
                borderRadius: "34px",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;",
                marginTop: "-41px",
                marginLeft: "16px",
                padding: "17px 3em 0 1em",
                position: "fixed",
                top: "132px",
                display: isSmallScreen ? 'none' : 'block',
            }}
        >
            {menu.map((item, index) => (
                (item.title === "Admin Management" && auth?.user?.role != 'Super Admin') ? null :
                    <Box
                        key={index}
                        onClick={() => switchPage(item.route)}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            height: "43px",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#FFFFFF",
                            margin: "0px 4px",
                            padding: "13px",
                            position: "relative",
                            justifyContent: "space-between",
                            border: item.title === page ? "1px solid #FFFFFF" : "none",
                            borderRadius: "20px",
                            background: item.title === page ? "linear-gradient(105deg, #FFFFFF4D 51%, #FFFFFF24 100%)" : "none",
                            boxShadow: item.title === page ? "rgba(0, 0, 0, 0.1) 0px 4px 12px;" : "none",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <Icon
                                name={item.title === page ? item.selectedIcon : item.icon}
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "6px",
                                }}
                            />
                            <p style={{
                                fontSize: "13px",
                                height: "22px",
                                marginLeft: "12px",
                                paddingTop: "8px",
                                font: "normal normal Regular 13px Poppins",
                            }}>{item.title}</p>

                        </div>

                        <Icon
                            name={item.title === page ? "RightArrow" : "DownArrow"}
                            style={{
                                width: "12px",
                                height: "13px",
                                marginTop: "4px",
                            }}
                        />
                    </Box>
            ))}

            <Box
                onClick={() => switchPage('/support-token')}
                sx={{
                    display: "grid",
                    width: "81%",
                    cursor: "pointer",
                    color: "#FFFFFF",
                    margin: "0px 4px",
                    padding: "13px",
                    position: "absolute",
                    bottom: "10px",
                    borderRadius: "24px",
                    left: "10px",
                    backgroundColor: "#2593D6 !important",
                }}
            >
                <img
                    alt="test"
                    src={chatSupport}
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute"
                    }}
                />
                <Icon
                    name="ChatSupport"
                    style={{
                        width: "35px",
                        height: "31px",
                        marginTop: "6px",
                        marginLeft: "16px"
                    }}
                />
                <h5 style={{
                    fontSize: "16px",
                    marginLeft: "12px",
                    paddingTop: "8px",
                    color: "#FFFFFF",
                    font: "normal normal 600 16px/26px Poppins",
                }}>Support Chats</h5>

                <h5 style={{
                    fontSize: "12px",
                    marginLeft: "12px",
                    paddingTop: "-15px",
                    opacity: 0.8,
                    color: "#FFFFFF",
                    font: "normal normal normal 12px/21px Poppins",
                }}>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's.</h5>
            </Box>
        </Box>
    );
};

export default Sidebar;
