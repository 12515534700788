import * as React from 'react';
import { Box } from '@mui/material';
import Icon from "../Helpers/Icon";

const AccountingCard = ({ isShow, icon, number, text }) => {

    return (
        <Box sx={{
            display: 'grid',
            height: "130px",
            width: "96%",
            background: "#F8F8F8 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            marginRight: "20px",
            marginBottom: "16px",
            paddingLeft: "21px",
            paddingRight: "24px",
        }}>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <span style={{
                    color: "#000000",
                    fontSize: "13px",
                    font: "normal normal normal 15px/25px Poppins",
                }}>1943 N Campus Ave, Upland, California, 91784</span>
                <span style={{
                    color: "#2C80FF",
                    fontSize: "14px",
                    marginLeft: "5px",
                    font: "normal normal 600 14px/23px Poppins",
                }}>$410.00</span>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                height: '60px',
                width: '100%',
                borderRadius: '5px',
            }}>
                <div className='iconContainer'>
                    <Icon
                        name="Order"
                        style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                        }}
                    />

                    <span style={{ color: '#000000', fontSize: '12px', font: 'normal normal normal 12px/19px Poppins' }}>Order ID: #789</span>
                </div>

                <div className='iconContainer'>
                    <Icon
                        name="Invoice"
                        style={{
                            marginTop: "4px",
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                        }}
                    />

                    <span style={{ color: '#000000', fontSize: '12px', font: 'normal normal normal 12px/19px Poppins' }}>Order ID: #789</span>
                </div>

                <div className='iconContainer'>
                    <Icon
                        name="Calender"
                        style={{
                            marginTop: "4px",
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                        }}
                    />

                    <span style={{ color: '#000000', fontSize: '12px', font: 'normal normal normal 12px/19px Poppins' }}>Order ID: #789</span>
                </div>
            </Box>
        </Box>
    );
}

export default AccountingCard;