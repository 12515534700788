import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import Icon from "../Helpers/Icon";
import Grid from "./DataGrid";
import {
  getAllAppraiser,
  getAllClient,
  getAllInspector,
  getAllReviewer,
  getOrderHistory,
} from "../Actions/dashboard";
import { clientsColumns, usersColumns } from "../Helpers/DataHelper";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";

const PopAnalyticsCard = ({ text, handleClose }) => {
  const [orderHistory, setOrderHistory] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [data, setData] = useState([]);

  useEffect(() => {
    // Generate month names for x-axis
    const monthNames = Array.from({ length: 12 }, (_, index) => {
      const date = new Date(2000, index); // Use arbitrary year and day
      return date.toLocaleString("default", { month: "short" });
    });
    // setMonths(monthNames);
    // getOrderHistoryData();
    setIsLoading(true);
    getUsersData();
    // console.log("text",text)
  }, []);

  // const getOrderHistoryData = async () => {
  //   try {
  //     const result = await dispatch(getOrderHistory());
  //     if (result && result.data) {
  //       const allMonthsData = Array.from({ length: 12 }, (_, index) => {
  //         const monthData = result.data.find(
  //           (item) => item.month === index + 1
  //         );
  //         return monthData ? monthData.count : 0;
  //       });
  //       setOrderHistory(allMonthsData);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred while fetching data:", error);

  //     setIsLoading(false);
  //     setAlert({
  //       show: true,
  //       type: "error",
  //       message: error?.response?.data?.error || "An error occurred.",
  //     });
  //   }
  // };

  const getUsersData = async (type) => {
    if (!text) {
      setIsLoading(true);
    }

    setData([]);

    const params = {
      role: type,
      searchText: text && text.length ? text : null,
    };

    setIsLoading(false);

    if (text === "Total Clients") {
      try {
        const result = await dispatch(getAllClient(params));

        if (result && result.data && result.data.length) {
          setData(result.data);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);

        setIsLoading(false);
        setAlert({
          show: true,
          type: "error",
          message: error?.response?.data?.error || "An error occurred.",
        });
      }
    }

    if (text === "Total Revisions") {
      try {
        const result = await dispatch(getAllReviewer(params));
        if (result && result.data && result.data.length) {
          setData(result.data);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);

        setIsLoading(false);
        setAlert({
          show: true,
          type: "error",
          message: error?.response?.data?.error || "An error occurred.",
        });
      }
    }

    if (text === "Total Inspectors") {
      try {
        const result = await dispatch(getAllInspector(params));
        if (result && result.data && result.data.length) {
          setData(result.data);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);

        setIsLoading(false);
        setAlert({
          show: true,
          type: "error",
          message: error?.response?.data?.error || "An error occurred.",
        });
      }
    }

    if (text === "Total Appraisers") {
      try {
        const result = await dispatch(getAllAppraiser(params));

        if (result && result.data && result.data.length) {
          setData(result.data);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);

        setIsLoading(false);
        setAlert({
          show: true,
          type: "error",
          message: error?.response?.data?.error || "An error occurred.",
        });
      }
    }
  };

  return (
    <Modal
      open={text}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
          boxShadow: "0px 8px 15px #00000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          mb: "1em",
          width: "88%",
          margin: "auto",
          marginTop: "5rem",
          left: "1rem",
        }}
      >
        <Box
          sx={{
            mb: "1em",
            width: "100%",
            paddingTop: "12px",
            textAlign: "center",
            paddingBottom: "2em",
            marginBottom: "-24px",
            backgroundColor: "white",
            borderRadius: "14px",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#000000",
              fontSize: "20px",
              font: "normal normal 600 20px/33px Poppins",
            }}
          >
            {text}
          </span>
          <Icon
            onClick={handleClose}
            name="Cancel"
            style={{
              width: "42px",
              height: "42px",
              position: "absolute",
              right: "20px",
              cursor: "pointer",
            }}
          />
        </Box>

        <Grid
          pageData={7}
          isLoading={isLoading}
        //   data= text != "Total Inspector" ? data :
        //     {data.map(user => ({
        //     ...user,
        //     licenseNumber: user.licenseId[0].licenseNumber
        //   })) } 
        // }      
        
        data ={
 text !== "Total Inspectors" ? data : 
        data.map(user => ({
            ...user,
            licenseNumber: user?.licenseId[0]?.licenseNumber
        }))
 }

           columns={text === "Total Clients" ?clientsColumns:usersColumns}
        />
      </Box>
    </Modal>
  );
};

export default PopAnalyticsCard;
