import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import Icon from "../Helpers/Icon";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";


const EditMenu = ({page}) => {
  let data =[];

  if(page === "Inspector"){
     data = [
      {
        title: "State License(s)",
        selected: true,
        route: "/inspector-profile"
      },
      {
        title: "Personal Information",
        selected: false,
        route: "/inspector-profile"
    
      },
      {
        title: "Company Information",
        selected: false,
        route: "/inspector-profile"
    
      },
      {
        title: "Billing",
        selected: false,
        route: "/inspector-profile"
    
      },
      {
        title: "Coverage Area",
        selected: false,
        route: "/inspector-profile"
    
      },
      {
        title: "Documents",
        selected: false,
        route: "/inspector-profile"
    
      },
    ];
  }else if(page === "Appraiser"){
     data = [
      {
        title: "State License(s)",
        selected: true,
        route: "/appraiser-profile"
      },
      {
        title: "Personal Information",
        selected: false,
        route: "/appraiser-profile"
    
      },
      {
        title: "Company Information",
        selected: false,
        route: "/appraiser-profile"
    
      },
      {
        title: "Appraiser Qualification",
        selected: false,
        route: "/appraiser-profile"
    
      },
      {
        title: "E & O Insurance",
        selected: false,
        route: "/appraiser-profile"
    
      },

      {
        title: "Coverage Area",
        selected: false,
        route: "/appraiser-profile"
    
      },
      {
        title: "Billing",
        selected: false,
        route: "/appraiser-profile"
    
      },
      {
        title: "Documents",
        selected: false,
        route: "/appraiser-profile"
    
      },
    ];
  }
  
  const [menu, setMenu] = useState(data);
  const navigate = useNavigate();
  const location = useLocation();
  const { type, id } = location.state;

  const switchPage = (route, title) => {
    let data = [...menu];
    let selected = data.find((e) => e.title === title);
  
    if (selected.selected) {
      return;
    }
  
    data = data.map((e) => ({ ...e, selected: false }));
  
    selected.selected = true;
  
    // setMenu(data);
    setMenu(prevMenu => {
      const newMenu = prevMenu.map(item => ({
        ...item,
        selected: item.title === title
      }));
  
      return newMenu;
    });

    navigate(route, { state: { title: title, id: id } });
  };
  



  return (
    <Box
      className=""
      sx={{
        zIndex: 1,
        position: "fixed",
        left: "10px",
      }}
    >
      <Box
        sx={{
          height: "fit-content",
          background: "linear-gradient(180deg, #2593D6 0%, #134A6B 100%)",
          borderRadius: "34px",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;",
          marginLeft: "16px",
          padding: "17px 3em 2em 1em",
          top: "132px",
        }}
      >
        <p
          style={{
            fontSize: "1.5rem",
            marginLeft: "28px",
            marginTop: "20px",
            marginBottom: "20px",
            font: "normal normal Regular 13px Poppins",
            color: "#FFFFFF",
          }}
        >
          Edit {page} 
        </p>
        {menu.map((item, index) => (
          <Box
            key={index}
            onClick={() => switchPage(item.route,item.title)}
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "23px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color:
                item.selected === true ? "#FFFFFF" : "rgba(255, 255, 255, 0.6)",
              margin: "0px 4px",
              padding: "13px",
              position: "relative",
              justifyContent: "space-between",

              borderRadius: "20px",
            }}
          >
            <Box style={{ display: "flex" }}>
              <p
                style={{
                  fontSize: "13px",
                  height: "22px",
                  marginLeft: "10px",
                  paddingTop: "8px",
                  font: "normal normal Regular 13px Poppins",
                }}
              >
                {item.title}
              </p>
            </Box>

            <Icon
              name={item.selected === true ? "RightArrow" : "DownArrow"}
              style={{
                color:
                  item.selected === true
                    ? "#FFFFFF"
                    : "rgba(255, 255, 255, 0.6)",
                width: "12px",
                height: "13px",
                marginTop: "4px",
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EditMenu;
