import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Template from "../../Template";
import mokcup1 from "../..//Assets/Images/mokcup1.png";
import "./index.css";
import Input from "../../Components/Core/Input";
import Button from "../../Components/Core/Button";
import AlertBox from "../../Components/Alert";
import MailNegative from "../../Assets/Icons/Huge-icon-communication-outline-mail-negative.svg";
import Shield from "../../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import OutlineBook from "../../Assets/Icons/huge-icon-education-outline-book.svg";
import OutlineStack from "../../Assets/Icons/huge-icon-education-outline-cube-stack.svg";
// import backgroundImage from "../..//Assets/Images/shutterstock_1569440923.png";
import backgroundImage from "../../Assets/Images/loginPage.png";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { loginUser } from "../../Actions/auth";
import { Navigate } from "react-router";
import { useTheme } from "@mui/material/styles";


const validationSchema = yup.object({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setRemember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Errors, setErrors] = useState(null);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(755));

  if (auth.authenticate) {
    return <Navigate to={"/dashboard"} />;
  }

  const handleLogin = async () => {
    setIsLoading(true);

    const data = {
      email,
      password,
      role:"Admin"
    };

    try {
      await validationSchema.validate(data, {
        abortEarly: false,
      });


      dispatch(loginUser(data))
        .then((result) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'success', message: "Logged in successfully." });
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'error', message: err?.response?.data?.error });
        });
    } catch (validationError) {
      const formattedErrors = {};
      validationError.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });

      setErrors(formattedErrors);
      setIsLoading(false);
    }
  };


  return (
    <Template
      showHeader={true}
      headerProps={{
        transparent: true,
        backgroundImage: backgroundImage,
      }}
    >
      <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />

      <Box
        sx={{
          mt: "-6em",
          width: "88%",
          ml: "auto",
          mr: "auto",
          display: isVerySmallScreen?" ":"flex",
          flexDirection: "row",
          position: "relative",
          pb: "5em",
        }}
      >
        <img
          src={mokcup1}
          style={{
            // marginTop: "3em",
            width:isSmallScreen ?"20%":"30%" ,
            width: isVerySmallScreen?"80%":"30%",
            // height: isSmallScreen ?" 60%" :"100%",

          }}
        />
        <Box
          sx={{
            // width: "200px !important",
            width: isVerySmallScreen?"90%":"25%" ,
            mt: isVerySmallScreen ?"2em":"7.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width:"100%",

            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineBook}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width:"100%",
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineStack}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>
        </Box>
        <Box className="login_Box"
        sx={{ padding : isVerySmallScreen ?"1rem":"" }}
        style={{
          width: isVerySmallScreen?"80%":"25%",
          marginTop :isVerySmallScreen?"5rem":"",
           
        }}
        >
          <h3>Sign in</h3>
          <p>Sign in to with your email</p>
          <Input
            icon={
              <img
                src={MailNegative}
                style={{ width: "18px", height: "18px" }}
              />
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
            type="email"
            style={{ marginTop: "1em" }}
            error={Errors?.email || ""}
          />
          <Input
            icon={
              <img src={Shield} style={{ width: "18px", height: "18px" }} />
            }
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Your password"
            type={"password"}
            style={{ margin: ".5em 0" }}
            error={Errors?.password || ""}
          />
          <Box
            sx={{
              display: isSmallScreen ?" ":"flex",
              flexDirection: "row",
              width: "100%",
              margin: ".7em 0",
            }}
          >
            <div className="rememberBox">
              <input
                type="checkbox"
                checked={isRemember}
                onChange={() => setRemember(!isRemember)}
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label
                for="vehicle1"
                style={{
                  marginLeft: ".5em",
                  font: " normal normal normal 12px/18px Poppins",
                  color: "#656a72",
                  marginTop: "5px"
                }}
              >
                Remember me
              </label>
            </div>
            <p
              style={{
                marginLeft: "auto",
                color: "#2B80FF",
                cursor: "pointer",
                fontSize: "12px !important",
                font: "normal normal normal 12px/18px Poppins",
              }}
              onClick={() => {
                window.location.href = "/forgot-password";
              }}
            >
              Forgot password?
            </p>
          </Box>
          <Button isLoading={isLoading} onClick={handleLogin}>
            Login
          </Button>
        </Box>
      </Box>
    </Template>
  );
};

export default Login;
