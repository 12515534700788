import { Box } from "@mui/material";
import React from "react";
import Text from "./Core/Text";
import { RemoveThin } from "react-huge-icons/outline";
import Button from "./Core/Button";


const TicketInfo = ({data,handleClose }) => {

  return (
      <Box
        sx={{
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          width: "30rem",
          height: "auto",
          zIndex: 1,
          m: "auto",
          padding: "2em",
          position: "relative",
          marginTop: "4em",
          border:"none"

        }}
      >
        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Ticket Info
        </Text>
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            backgroundColor: "#fff",
          }}
        >
          <RemoveThin fontSize={28} onClick={handleClose} />
        </Box>

        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            fontWeight: "bold",
          }}
        >
          Ticket Title
        </Text>
        <Text
          style={{
            font: "normal normal normal 12px/22px Poppins",
            color: "#000000",
            marginBottom: "1em",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "0.6",
          }}
        >
          {data?.ticketTitle}
        </Text>

        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            fontWeight: "bold",
          }}
        >
          Ticket Number{" "}
        </Text>
        <Text
          style={{
            font: "normal normal normal 12px/22px Poppins",
            color: "#000000",
            marginBottom: "1em",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "0.6",
          }}
        >
          #{data?._id}
        </Text>

        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            fontWeight: "bold",
          }}
        >
          Subject{" "}
        </Text>
        <Text
          style={{
            font: "normal normal normal 12px/22px Poppins",
            color: "#000000",
            marginBottom: "1em",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "0.6",
          }}
        >
          {data?.Subject}
        </Text>

        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            fontWeight: "bold",
          }}
        >
          Description
        </Text>
        <Text
          style={{
            font: "normal normal normal 12px/22px Poppins",
            color: "#000000",
            marginBottom: "1em",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "0.6",
          }}
        >
          {data?.ticketdesc}
        </Text>
      </Box>
  );
};

export default TicketInfo;
