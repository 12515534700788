import { Box } from '@mui/material';
import Icon from "../Helpers/Icon";
import React, { useEffect, useState } from 'react';
import { getImageUrl } from '../Helpers/Constants';
import "./index.css";

const Attachment = ({ images, documents, handleClose }) => {

    return (
        <Box
            sx={{
                mb: "1em",
                width: "50%",
                margin: "auto",
                marginTop: "5em",
                background: "white",
                borderRadius: "20px",
            }}
        >
            <Box
                sx={{
                    mb: "1em",
                    width: "100%",
                    paddingTop: "12px",
                    textAlign: "center",
                    position: "relative",
                    paddingBottom: "2em",
                    marginBottom: "-24px",
                    backgroundColor: "white",
                    borderRadius: "14px",
                }}
            >
                <span style={{ color: '#000000', fontSize: '15px', font: 'normal normal 600 15px/25px Poppins' }}>Attached Documents</span>
                <Icon
                    onClick={() => handleClose()}
                    name="Cancel"
                    style={{
                        width: "42px",
                        height: "42px",
                        position: "absolute",
                        right: "20px",
                        cursor: "pointer"
                    }}
                />
            </Box>

            <h2 style={{ color: '#000000', marginTop: "2em", marginLeft: "24px", fontSize: '12px', font: 'normal normal 600 12px/19px Poppins' }}>Images</h2>
            <Box
                sx={{
                    mb: "1em",
                    width: "100%",
                    paddingTop: "12px",
                    position: "relative",
                    paddingBottom: "2em",
                    backgroundColor: "white",
                    borderRadius: "14px",
                    display: 'flex',
                    flexDirection: "row"
                }}
            >
                {images && images.length ? (
                    images.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                mb: "1em",
                                width: "147px",
                                height: "153px",
                                paddingTop: "12px",
                                position: "relative",
                                borderRadius: "16px",
                                display: 'grid',
                                background: "#F9F9F9 0% 0% no-repeat padding-box",
                                textAlign: "center",
                                marginLeft: "20px"
                            }}
                        >
                            <img alt='images'
                                src={item}
                                style={{
                                    width: "132px",
                                    height: "93px",
                                    borderRadius: "14px",
                                    padding: "10px"
                                }}
                            />

                            {/* <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal medium 11px/18px Poppins' }}>{item}</span> */}

                            <span style={{ color: '#000000', fontSize: '9px', opacity: 0.5, font: 'normal normal normal 9px/15px Poppins' }}>41.5 KB</span>
                        </Box>
                    ))
                ) : (
                    <span style={{ color: '#000000', margin: "Auto", opacity: 0.7, fontSize: '9px', font: 'normal normal 600 9px/19px Poppins' }}>No Images Available</span>
                )}

            </Box>

            <h2 style={{ color: '#000000', marginTop: "2em", marginLeft: "24px", fontSize: '12px', font: 'normal normal 600 12px/19px Poppins' }}>Documents</h2>
            <Box
                sx={{
                    mb: "1em",
                    width: "100%",
                    paddingTop: "12px",
                    position: "relative",
                    paddingBottom: "2em",
                    backgroundColor: "white",
                    borderRadius: "14px",
                    display: 'flex',
                    flexDirection: "row"
                }}
            >
                {documents && documents.length ? (
                    documents.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                mb: "1em",
                                width: "147px",
                                height: "153px",
                                paddingTop: "12px",
                                position: "relative",
                                borderRadius: "16px",
                                display: 'grid',
                                background: "#F9F9F9 0% 0% no-repeat padding-box",
                                textAlign: "center",
                                marginLeft: "20px"
                            }}
                        >
                            <a
                                href={item}
                                download={item}
                                style={{
                                    width: "132px",
                                    height: "93px",
                                    borderRadius: "14px",
                                    padding: "10px",
                                    background: "#2C80FF 0% 0% no-repeat padding-box",
                                    opacity: 0.6
                                }}
                            >
                                <Icon
                                    name="File"
                                    style={{
                                        width: "26px",
                                        height: "28px",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                    }}
                                />
                            </a>

                            {/* <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal medium 11px/18px Poppins' }}>{item}</span> */}

                            <span style={{ color: '#000000', fontSize: '9px', opacity: 0.5, font: 'normal normal normal 9px/15px Poppins' }}>41.5 KB</span>
                        </Box>
                    ))
                ) : (
                    <span style={{ color: '#000000', fontSize: '9px', margin: "Auto", opacity: 0.7, font: 'normal normal 600 9px/19px Poppins' }}>No Documents Available</span>
                )}

            </Box>
        </Box>
    );
};

export default Attachment;
