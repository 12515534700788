import React, { useState, useRef } from 'react';

const FourDigitInput = ({ onChange }) => {
    const [digits, setDigits] = useState(['', '', '', '']);
    const refs = [useRef(), useRef(), useRef(), useRef()];

    const handleDigitChange = (index, value) => {
        const newDigits = [...digits];
        newDigits[index] = value;

        setDigits(newDigits);

        // Move focus to the next input
        if (value !== '' && index < 3) {
            refs[index + 1].current.focus();
        }

        // Move focus to the previous input if value is deleted
        if (value === '' && index > 0) {
            refs[index - 1].current.focus();
        }

        // Call the onChange callback with the concatenated string of all four digits
        if (onChange) {
            onChange(newDigits.join(''));
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "1em 0" }}>
            {digits.map((digit, index) => (
                <input
                    key={index}
                    ref={refs[index]}
                    id={`digit-${index}`}
                    type="text"
                    value={digit}
                    onChange={(e) => handleDigitChange(index, e.target.value)}
                    maxLength={1}
                    style={{
                        width: '23%',
                        height: '40px',
                        margin: '0 5px',
                        borderRadius: '11px',
                        textAlign: 'center',
                        fontSize: '16px',
                        border: "none",
                        outline: "none",
                        background: "rgba(242, 242, 242, 1) 0% 0% no-repeat padding-box",
                        color: "rgba(101, 106, 114, 1)",
                        font: "normal normal medium 14px/21px Poppins",
                    }}
                />
            ))}
        </div>
    );
};

export default FourDigitInput;
