import * as yup from "yup";

export const personalInfoSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  cellPhone: yup.string().required("Cell Phone is required"),
  workPhoneNumber: yup.string().required("Work Phone Number is required"),
  languageSpoken: yup.string().required("Language Spoken is required"),
  streetAddress: yup.string().required("Street Address/PO Box is required"),
  state: yup.string().required("State is required"),
  cityTown: yup.string().required("City/Town is required"),
  zipcode: yup.string().required("Zipcode is required"),
  demographicsCityTown: yup.string().optional(),
  demographicsZipcode: yup.string().optional(),
});

export const adminInfoSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  firstName: yup.string().min(3, "First Name should be at least 3 letters").required("First Name is required"),
  lastName: yup.string().min(3, "Last Name should be at least 3 letters").required("Last Name is required"),
  cellPhone: yup.string().required("Cell Phone is required"),
  states: yup.array().min(1, "Select at least one state").required("State is required"),
});

export const clientInfoSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  firstName: yup.string().min(3, "First Name should be at least 3 letters").required("First Name is required"),
  lastName: yup.string().min(3, "Last Name should be at least 3 letters").required("Last Name is required"),
  cellPhone: yup.string().required("Cell Phone is required"),
});

export const usrInfoSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  firstName: yup.string().min(3, "First Name should be at least 3 letters").required("First Name is required"),
  lastName: yup.string().min(3, "Last Name should be at least 3 letters").required("Last Name is required"),
  cellPhone: yup.string().required("Cell Phone is required"),
  state: yup.array().min(1, "Select at least one state").required("State is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string().required("Zip Code is required"),
  companyName: yup.string().required("Company Name is required"),
  companyPhoneNumber: yup.string().required("Company Phone Number is required"),
  companyAddress1: yup.string().required("Company Address 1 is required"),
  companyAddress2: yup.string().required("Company Address 2 is required"),
  companyState: yup.string().required("Company State is required"),
  companyCity: yup.string().required("Company City is required"),
  companyZipCode: yup.string().required("Company Zip Code is required"),
});

export const modifyOrderSchema = yup.object().shape({
  fee: yup.string().required("Modify Fee is required"),
  date: yup.date().required("Modify Date is required"),
  note: yup.string().required("Modify Note is required"),
});
