import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import Icon from "../Helpers/Icon";

import { assignedOrderHeader } from "../Helpers/DataHelper";

import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropertyCard from "./PropertyCard";
import {
  appraiserOrderAssign,
  getAssignedOrderOfAppraiser,
  getRemainingOrder,
} from "../Actions/users";
import Button from "./Core/Button";
import AlertBox from "./Alert";

const PopAssignedOrder = ({ id, text, handleClose }) => {
  const [orderHistory, setOrderHistory] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [data, setData] = useState([]);
  const [header, setHeader] = useState(assignedOrderHeader);
  const [selectedIds, setSelectedIds] = useState([]);

  const [pageType, setPageType] = useState("assigned");

  const getAllGridData = async (type) => {
    setIsLoading(true);
    setPageType(type);

    setIsLoading(false);
  };

  useEffect(() => {
    getAssignedOrders();
  }, []);

  useEffect(() => {
    if (pageType === "assigned") {
      getAssignedOrders();
    }
    if (pageType === "newOrders") {
      getOrdersRemaining();
    }
  }, [pageType]);

  const getAssignedOrders = async () => {
    setIsLoading(true);
    setData([]);
    try {
      const result = await dispatch(getAssignedOrderOfAppraiser(id));
      if (result && result.data) {
        setData(result.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred while fetching user data:", error);

      setIsLoading(false);
      setAlert({
        show: true,
        type: "error",
        message: error?.response?.data?.error || "An error occurred.",
      });
    }
  };

  const getOrdersRemaining = async () => {
    setIsLoading(true);
    setData([]);
    try {
      const result = await dispatch(getRemainingOrder());
      if (result && result.data) {
        setData(result.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred while fetching user data:", error);

      setIsLoading(false);
      setAlert({
        show: true,
        type: "error",
        message: error?.response?.data?.error || "An error occurred.",
      });
    }
  };

  const assignOrder = (id) => {

    if(selectedIds.length<0){
      setAlert({
        show: true,
        type: "error",
        message: "Select an order to assign",
      });

      return;
    }

    setIsLoading(true);
  

    console.log(selectedIds)


    try {
      setIsLoading(false);
      dispatch(appraiserOrderAssign(id, selectedIds))
        .then((result) => {
          getOrdersRemaining();
          setAlert({
            show: true,
            type: "success",
            message: "Order Assigned Successfully",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: "Select an order to assign",
          });
        });
    } catch (error) {
      console.error("Error occurred while fetching user data:", error);

      setIsLoading(false);
      setAlert({
        show: true,
        type: "error",
        message: error?.response?.data?.error || "An error occurred.",
      });
    }

    setSelectedIds([]);
  };

  return (
    <>
    
    <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />

    <Modal
      open={text}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >


      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
          boxShadow: "0px 8px 15px #00000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          mb: "1em",
          width: "88%",
          margin: "auto",
          marginTop: "3rem",
          left: "1rem",
        }}
      >
        <Box
          sx={{
            mb: "1em",
            width: "100%",
            paddingTop: "12px",
            textAlign: "center",
            paddingBottom: "2em",
            marginBottom: "-24px",
            backgroundColor: "white",
            borderRadius: "14px",
            position: "relative",
          }}
        >
          <Box>
            <span
              style={{
                color: "#000000",
                fontSize: "20px",
                font: "normal normal 600 20px/33px Poppins",
              }}
            >
              Assigned Orders
            </span>

            <Icon
              onClick={handleClose}
              name="Cancel"
              style={{
                width: "42px",
                height: "42px",
                position: "absolute",
                right: "20px",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "6px",
              marginLeft: "40px",
              marginBottom: "-17px",
            }}
          >
            {header.map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  background:
                    pageType === item.value
                      ? "rgba(239,238,238,1)"
                      : "#F8F8F8 0% 0% no-repeat padding-box",
                  height: "59px",
                  width: "189px",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: pageType === item.value ? "#2D81FF" : "#000000",
                  margin: "0px 4px",
                  paddingTop: "2px",
                  position: "relative",
                  opacity: pageType === item.value ? 1 : 0.3,
                  borderRadius: "20px 20px 0px 0px;",
                }}
              >
                <Box
                  onClick={() => getAllGridData(item.value)}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      color:
                        pageType === item.value
                          ? "#2593D6 !important"
                          : "#000000",
                    }}
                  >
                    <Icon
                      name={
                        item.value === pageType ? item.selectedIcon : item.icon
                      }
                      style={{
                        width: "17px",
                        height: "19px",
                        marginTop: "6px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      font: "normal normal normal 13px Poppins",
                      color: pageType === item.value ? "#2593D6" : "#000000",
                      marginLeft: "10px",
                    }}
                  >
                    {item.label}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              borderRadius: "16px",
              background: "rgba(239,238,238,1)",
              opacity: 1,
              padding: "1.5em",
              mt: "1em",
              overflowX: "hidden",
              overflowY: "scroll",
              height: "55vh",
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={40}
                sx={{
                  color: "#2C80FF",
                  marginRight: "6px",
                  display: "flex",
                  margin: "Auto",
                }}
              />
            ) : (
              <>
                {data.length>0 ? (
                  data.map((item, index) => (
                    <PropertyCard
                      key={index}
                      data={item}
                      type={pageType}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                    />
                  ))
                ) : (
                  <h1
                    style={{
                      font: "normal normal normal 1rem Poppins",
                      textAlign: "center",
                      
                    }}
                  >
                    No data
                  </h1>
                )}
              </>
            )}
          </Box>
          {pageType == "newOrders" && (
            <Box
              className="iconContainer"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <Button
                style={{
                  width: "114px",
                  marginTop: "auto",
                  marginBottom: "14px",
                  height: "40px",
                  paddingLeft: "5rem",
                  paddingRight: "5rem",
                }}
                onClick={() => assignOrder(id)}
              >
                Assign
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
    </>
  );
};

export default PopAssignedOrder;
