import React from 'react';
import "./index.css";
import Button from "./Core/Button";
import Input from "./Core/Input";
import Select from "./Core/Select";
import { Box } from "@mui/material";

const AddAdmin = ({ states,
    handleChange,
    data,
    errors,
    addAdmin,
    isLoading,
    onCancel }) => {
    return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "43em",
            bgcolor: 'background.paper',
            borderRadius: "16px",
            boxShadow: 24,
            p: 4,
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    mt: "2em",
                }}
            >
                <Input
                    placeholder="First Name"
                    label="First Name"
                    type="text"
                    value={data.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    style={{ width: "100%" }}
                    error={errors.firstName || ""}
                />

                <Input
                    placeholder="Last Name"
                    label="Last Name"
                    type="text"
                    style={{ width: "100%", marginLeft: "1em" }}
                    value={data.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    error={errors.lastName || ""}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    mt: "2em",
                }}
            >

                <Input
                    placeholder="Email"
                    label="Email"
                    type="text"
                    style={{ width: "100%" }}
                    value={data.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    error={errors.email || ""}
                />

                <Input
                    placeholder="Cell Phone"
                    label="Cell Phone"
                    type="number"
                    style={{ width: "100%", marginLeft: "1em" }}
                    value={data.cellPhone}
                    onChange={(e) => handleChange("cellPhone", e.target.value)}
                    error={errors.cellPhone || ""}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    mt: "2em",
                }}
            >
                <Select
                    multiple
                    label="Assign States"
                    style={{ width: "50%"}}
                    options={states}
                    value={data.states || []}
                    onChange={(option) => {
                        handleChange("states", option);
                    }}
                    error={errors.states || ""}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    mt: "2em",
                    justifyContent: "center",
                }}
            >
                <Button
                    style={{
                        width: "8em",
                        marginTop: "auto",
                        marginBottom: "14px",
                        height: "40px",
                    }}
                    onClick={addAdmin} isLoading={isLoading}
                >
                    Add Admin
                </Button>

                <Button
                    style={{
                        width: "8em",
                        marginTop: "auto",
                        marginBottom: "14px",
                        marginLeft: "20px",
                        height: "40px",
                    }}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    );
};

export default AddAdmin;
