import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import Shield from "../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import Input from "./Core/Input";
import Button from "./Core/Button";
import { FaTimes } from "react-icons/fa";
import AlertBox from "./Alert";
import { logout, resetPassword } from "../Actions/auth";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import axios from "../Utils/axios";
import { apiUrls } from "../Helpers/Constants";

const ChangePassword = ({ handleClose }) => {
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const [showPassword, setshowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldLogOut, setShouldLogOut] = useState(false);


  const navigation = useNavigate();
  const dispatch = useDispatch();

  
  
  const logOut = async () => {
    
    dispatch(logout())
    navigation('/login');
  };
  

  const updatePassword =async()=>{

    try {

      const data = {
        oldpassword,newpassword
      }


      const res = await axios.post(apiUrls.resetPassword, data);
      if (res.status === 200) {
         setAlert({ show: true, type: 'success', message: "Password reset successfully." });

      }

  } catch (err) {
    setAlert({ show: true, type: 'error', message: err?.response.data?.error });
    
    setIsLoading(false);
  }

  }


  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== newpassword) {
      setErrorMessage('Password do not match');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <Box className="mainModal">
      <Box
        sx={{
          width: "670px",
          height: "300px",
          background:
            "var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box, #FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 20px 20px #0000000B",
          borderRadius: "15px",
          opacity: "1",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
            <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />

        <Box>
          <span
            style={{
              font: "normal normal 600 17px/26px Poppins",
              color: "#000000",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            Change Password
          </span>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 40,
              boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
              backgroundColor: "#fff",
            }}
          >
            <FaTimes
              style={{
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>
        </Box>

        <Input
          icon={<img src={Shield} style={{ width: "18px", height: "18px" }} />}
          value={oldpassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Old password"
          type={showPassword ? "text" : "password"}
          style={{ margin: ".5em 0", width: "86%" }}
        />
        <Input
          icon={<img src={Shield} style={{ width: "18px", height: "18px" }} />}
          value={newpassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New password"
          type={showPassword ? "text" : "password"}
          style={{ margin: ".5em 0", width: "86%" }}
        />

        <Input
          icon={<img src={Shield} style={{ width: "18px", height: "18px" }} />}
          value={confirmpassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Confirm password"
          type={showPassword ? "text" : "password"}
          style={{ margin: ".5em 0", width: "86%" }}
        />
          
     
       {errorMessage && <p style={{ margin: ".5em 0", width: "86%" ,color:"red" }}>{errorMessage}</p>}


        <Box>

        <Button
          onClick={updatePassword}
         
          style={{
            padding:".2em 2em .2em 2em",
            marginTop: ".5em",
          }}
        >
          Update Password
        </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
