/* eslint-disable import/no-anonymous-default-export */
import { stateConstants } from "../Actions/constants";

const initState = {
    loading: false,
    error: null,
    message: "",
    states: null,
};

export default (state = initState, action) => {
    switch (action.type) {
        case stateConstants.STATE_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case stateConstants.STATE_SUCCESS:
            state = {
                ...state,
                states: action.payload.data,
                message: action.payload.message,
                loading: false,
            };
            break;
        case stateConstants.STATE_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        default:
    }
    return state;
};