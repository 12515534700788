import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import Icon from "../Helpers/Icon";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";




const data = [{
    route: "Dashboard",
    icon: "Home",
    selectedIcon: "HomeSelected",
    selected: true,
    route: "/dashboard"
},
{
    route: "User Management",
    icon: "UserManagement",
    selectedIcon: "UserManagementSelected",
    selected: false,
    route: "/user-management"
},
{
    route: "Admin Management",
    icon: "UserManagement",
    selectedIcon: "UserManagementSelected",
    selected: false,
    route: "/admin-management"
},
{
    route: "Accounting",
    icon: "Accounting",
    selectedIcon: "AccountingSelected",
    selected: false,
    route: "/accounting"
}, {
    route: "Messages",
    icon: "Message",
    selectedIcon: "MessageSelected",
    selected: false,
    route: "/message"
}];




const PopSideMenu = ({ handleClose }) => {

    const [menu,setMenu] = useState(data);
    const navigation = useNavigate();
    const auth = useSelector((state) => state.auth);


    const switchPage = (route) => {
        let data = [...menu];
        let selected = data.find((e) => e.route === route);
      
        if (selected.selected) {
          return;
        }
      
        data = data.map((e) => ({ ...e, selected: false }));
      
        selected.selected = true;
      
        setMenu(data);
    navigation(route);

      };

  return (
    <Box className=""
    sx={{
        zIndex: 1,
        position: "fixed",
        left: "10px",
    }}
    >   
     <Box
            sx={{
                height: "fit-content",
                background: "linear-gradient(180deg, #2593D6 0%, #134A6B 100%)",
                borderRadius: "34px",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;",
                marginTop: "-65px",
                marginLeft: "16px",
                padding: "17px 3em 2em 1em",
                position: "fixed",
                top: "132px",
            }}
        >
               <Icon
          onClick={() => handleClose()}
          name="Cancel"
          style={{
            width: "100%",
            height: "30px",
            position: "relative",
            right: "-120px",
            cursor: "pointer",
            top: "1px",
          }}
        />

            {menu.map((item, index) => (
                (item.route === "Admin Management" && auth?.user?.role != 'Super Admin') ? null :
                    <Box
                        key={index}
                        onClick={() =>switchPage(item.route)
                           
                        }
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            height: "23px",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#FFFFFF",
                            margin: "0px 4px",
                            padding: "13px",
                            position: "relative",
                            justifyContent: "space-between",
                            border: item.selected === true ? "1px solid #FFFFFF" : "none",
                            borderRadius: "20px",
                            background: item.selected === true ? "linear-gradient(105deg, #FFFFFF4D 51%, #FFFFFF24 100%)" : "none",
                            boxShadow: item.selected === true ? "rgba(0, 0, 0, 0.1) 0px 4px 12px;" : "none",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <Icon
                                name={item.selected === true ? item.selectedIcon : item.icon}
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "6px",
                                }}
                            />
                            <p style={{
                                fontSize: "13px",
                                height: "22px",
                                marginLeft: "12px",
                                paddingTop: "8px",
                                font: "normal normal Regular 13px Poppins",
                            }}>{item.route}</p>

                        </div>

                        <Icon
                            name={item.selected === true ? "RightArrow" : "DownArrow"}
                            style={{
                                width: "12px",
                                height: "13px",
                                marginTop: "4px",
                            }}
                        />
                    </Box>
            ))}

            <Box
                onClick={() => switchPage('/support-token')}
                sx={{
                    display: "grid",
                    width: "81%",
                    cursor: "pointer",
                    color: "#FFFFFF",
                    margin: "0px 4px",
                    padding: "13px",
                    position: "absolute",
                    bottom: "10px",
                    borderRadius: "24px",
                    left: "10px",
                    backgroundColor: "#2593D6 !important",
                }}
            >
        </Box>
        </Box>

    
      </Box>
  );
};

export default PopSideMenu;
