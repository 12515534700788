import { Box } from "@mui/material";
import React, { useState } from "react";
import "./index.css";
import Icon from "../Helpers/Icon";
import { logout } from "../Actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getInitials } from '../Helpers/Constants';

const PopMenu = ({ handleClose }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [storedData, setStoredData] = useState(() => JSON.parse(localStorage.getItem("user")) || {});


  const logOut = async () => {
    dispatch(logout())
    navigation('/login');
  };

  const onEditClick = async () => {
    navigation('/edit-profile', { state: { type: 'my-profile', id: auth?.user?._id } });
  }

  return (
    <Box className="mainModal">
      <Box
        sx={{
          boxShadow: "0px 10px 20px #0000000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          width: "236px",
          zIndex: 1,
          ml: "auto",
          mt: "4.4%",
          mr: "5%",
          padding: "1em",
          position: 'relative',
        }}
      >
        <Icon
          onClick={() => handleClose()}
          name="Cancel"
          style={{
            width: "42px",
            height: "42px",
            position: "absolute",
            right: "4px",
            cursor: "pointer",
            top: "5px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "0.8em 0",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              boxShadow: "0px 3px 6px #00000029",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: "50%",
              padding: ".5em",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <span
              style={{
                font: " normal normal 600 22px/23px Poppins",
                color: "#2C80FF",
              }}
            >
              {getInitials(storedData.firstName, storedData?.lastName)}
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              m: "auto 0",
            }}
          >
            <span
              style={{
                font: " normal normal 600 15px/23px Poppins",
                color: "#000000",
                marginLeft: "0.7em",
              }}
            >
              {storedData?.firstName} {storedData?.lastName}
            </span>

            <span
              style={{
                font: "normal normal normal 12px/18px Poppins",
                color: "#656A72",
                marginLeft: "0.7em",
              }}
            >
              {storedData?.email}
            </span>
          </Box>
        </Box>
        <div
          style={{
            width: "100%",
            height: "1.6px",
            backgroundColor: "#F2F2F2",
            margin: ".5em 0",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            m: "1em .4em",
          }}
        >
          <Icon
            name={"UserPolygon"}
            style={{
              width: "20px",
              height: "20px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
          <span
            onClick={onEditClick}
            style={{
              font: "normal normal medium 14px/21px Poppins",
              color: "#656A72",
              marginLeft: "0.5em",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Manage Profile
          </span>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            m: "1em .4em",
          }}
          onClick={handleClose}
        >
          <Icon
            name={"OutlineLogout"}
            style={{
              width: "20px",
              height: "20px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
          <span
            onClick={logOut}
            style={{
              font: "normal normal medium 14px/21px Poppins",
              color: "#E23C54",
              marginLeft: "0.5em",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Logout
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default PopMenu;
