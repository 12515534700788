import React, { useEffect, useRef, useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import Text from "../../Components/Core/Text";
import Input from "../../Components/Core/Input";
import { getMessageChatList, getChatHistory, sendChatMessage } from "../../Actions/message";
import {
  ChatPlus,
  MenuCircleVertical,
  PlusThin,
  Search,
  Send,
} from "react-huge-icons/outline";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getInitials } from '../../Helpers/Constants';
import Icon from "../../Helpers/Icon";
import { useTheme } from '@mui/material/styles';


function Message() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selected, setSelected] = useState(1);
  const [messageList, setMessageList] = useState([]);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const auth = useSelector((state) => state.auth);
  const [file, setFile] = useState(null);
  const [messageType, setType] = useState(null);
  const boxRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    getChatListData()
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    };

    scrollToBottom();
    setSelected(selected);
    setType(messageType);
    if (selected) {
      getMessageData(selected, messageType);
    }
  }, [messageList?.length]);

  useEffect(() => {
    if (selected) {
      const intervalId = setInterval(() => {
        getMessageData(selected, messageType);
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [selected]);


  const getChatListData = async () => {
    setIsLoading(true);
    setMessageList([]);

    try {
      const result = await dispatch(getMessageChatList());
      setIsLoading(false);
      if (result && result.data && result.data.length) {
        setMessageList(result.data)
        setFilteredList(result.data)
        const firstSupportTokenId = result.data[0]?.friendInfo?._id;
        setSelected(firstSupportTokenId);
        setType(result.data[0]);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);

      setIsLoading(false);
      setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
    }
  };


  const getMessageData = async (id, selectedData, isLoading) => {
    try {
      if (id === 1) {
        return;
      }
      if (isLoading) {
        setMessageList([]);
        setIsLoading1(true);
      }

      const result = await dispatch(getChatHistory(id, { type: selectedData.type }));
      if (result.data && result.data.length > 0) {
        // Update messageList with unique messages
        setMessageList(prevMessages => {
          const uniqueMessages = result.data.filter(message => (
            !prevMessages.some(prevMessage => prevMessage._id === message._id)
          ));
          return [...prevMessages, ...uniqueMessages];
        });
        setIsLoading1(false);
      }
    } catch (error) {
      console.error("Error occurred while fetching message data:", error);
      setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
    } finally {
      setIsLoading1(false);
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    const data = new FormData();
    data.append("message", newMessage);
    data.append("file", file);
    data.append("type", messageType.type);
    setNewMessage("");
    if (messageType.type === "Order") {
      data.append("orderId", messageType.orderId);
    }
    try {
      await dispatch(sendChatMessage(selected, data));
    } catch (error) {
      console.error("Error occurred while sending message:", error);
      setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
    }
  };

  const handleSelectChat = (item) => {
    setSelected(item?.friendInfo?._id);
    setType(item);
    // dispatch(getSupportTokenMessageByTokenId(id));
    getMessageData(item?.friendInfo?._id, item, true);
  };

  const filterList = () => {
    const filtered = messageList.filter(item =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.ticketNumber.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredList(filtered);
  };

  // Event handler for input change
  const handleInputChange = event => {
    setSearchQuery(event.target.value);
    if (!event.target.value) {
      setFilteredList(messageList);
    }
  };

  // Event handler for Enter key press
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      filterList();
    }
  };

  return (
    <Template
      showHeader
      showSidebar
      headerProps={{
        showSearch: true,
        showNotification: true,
        showProfile: true,
        showsideMenu:true

      }}
      sidebarProps={{
        page: "Message"
      }}
    
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <Box
        sx={{
          border: "1px solid #FFFFFF",
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
          boxShadow: "15px 15px 50px #00000029",
          borderRadius: "24px",
          backgroundColor: "#F2F2F2",
          opacity: "1",
          padding: "1.5em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          width: "73%",
          margin: "auto",
          width :isSmallScreen?"100%":"73%",
          marginLeft: isSmallScreen?"0":"20em",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ mr: "auto" }}>
            <Text variant="h2" style={{ font: 'normal normal 600 19px/31px Poppins' }}>All Messages</Text>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #FFFFFF",
            background:
              "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
            boxShadow: "15px 15px 50px #00000029",
            borderRadius: "24px",
            backgroundColor: "#F2F2F2",
            display: "flex",
            flexDirection: "row",
            opacity: 1,
            padding: "1.5em",
            backdropFilter: "blur(50px)",
            WebkitBackdropFilter: "blur(50px)",
            margin: "auto",
            backgroundImage: `url(${Ellipse8})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100vh",
            mt: "1em",
            backgroundPosition: "left -500px top -600px",
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={40}
              sx={{
                color: "#2C80FF",
                mr: "auto",
                mf: "auto",
              }}
            />
          ) : (
            <>
              <Box
                sx={{
                  mr: "auto",
                  width: "30%",
                  borderRight: "1.6px solid #ccc",
                  paddingRight: "1.6em",
                  mr: "1em",
                }}
              >
                <Input
                  placeholder="Search"
                  isTransparent
                  style={{
                    width: "100%",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "17px",
                    height: 56,
                  }}
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  icon={<Search style={{ color: "#A8A8A8", marginTop: -12 }} />}
                />
                <Box
                  sx={{
                    height: "80vh",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "0px",
                    }
                  }}
                >
                  {filteredList.map((item) => (
                    <Box
                      style={{
                        background: selected === item.from_id ? "#fff" : "transparent",
                        borderRadius: selected === item.from_id ? 20 : 0,
                        padding: "1em",
                        width: "90%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "1em",
                        cursor: "pointer",
                        borderBottom:
                          selected === item.from_id ||
                            filteredList[filteredList.length - 1].id === item.from_id
                            ? "none"
                            : "1px solid #ccc",
                      }}
                      onClick={() => handleSelectChat(item)}
                    >
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        <Text
                          style={{
                            color: "#011502",
                            font: "normal normal 600 13px/25px Poppins",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          {item.friendInfo?.firstName} {item.friendInfo?.lastName}
                        </Text>
                        <Text
                          style={{
                            color: "#8D8D8D",
                            marginLeft: "auto",
                            font: "normal normal normal 10px Poppins",
                            fontSize: "10px",
                          }}
                        >
                          {moment(item.updatedAt).fromNow()}
                        </Text>
                      </Box>
                      <Text
                        style={{
                          color: "#818181",
                          font: "normal normal normal 12px Poppins",
                          fontSize: "11px",
                          marginTop: "-.2em",
                        }}
                      >
                        {item.message}
                      </Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}


          <Box sx={{ width: "66%", paddingX: "1em" }}>
            {messageList && messageList.length && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                }}
              >

                <Box
                  sx={{
                    background: "#deecf4 0% 0% no-repeat padding-box",
                    borderRadius: "50%",
                    height: "51px",
                    width: "51px",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      font: "normal normal 600 19px/11px Poppins",
                      color: "#2593D6",
                      margin: "auto"
                    }}
                  >
                    {getInitials(messageList[0].friendInfo?.firstName, messageList[0].friendInfo?.lastName)}
                  </span>
                </Box>
                <div style={{
                  display: "grid",
                  position: "relative",
                }}>

                  <Text
                    style={{
                      color: "#011502",
                      font: "normal normal 600 13px/25px Poppins",
                      fontSize: "17px",
                      fontWeight: "600",
                      marginLeft: "17px"
                    }}
                  >
                    {messageList[0]?.friendInfo?.firstName} {messageList[0]?.friendInfo?.lastName}
                  </Text>
                  {messageList && messageList.length && messageList[0].type === "Order" && (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: '5px',
                    }}>
                      <div className='iconContainer' style={{ width: '100%', display: "flex" }}>
                        <Icon
                          name="Grid1"
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                          }}
                        />

                        <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal normal 11px/19px Poppins', whiteSpace: "nowrap" }}>Property ID: #{messageList[0]?.propertyId}</span>
                      </div>

                      <div className='iconContainer' style={{ width: '100%', display: "flex" }}>
                        <Icon
                          name="Grid1"
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                          }}
                        />

                        <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal normal 11px/19px Poppins', whiteSpace: "nowrap" }}>Address: {messageList[0]?.propertyAddress}</span>
                      </div>

                    </Box>
                  )}

                </div>
              </Box>
            )}

            <Box
              sx={{
                height: "80vh",
                overflowY: "scroll",
                pr: "1em",
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#F0F0F0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#2C80FF",
                  borderRadius: "4px",
                },
                scrollbarColor: "#2C80FF #F0F0F0",
                scrollbarWidth: "thin",
              }}
              ref={boxRef}
            >
              {isLoading1 ? (
                <CircularProgress
                  size={40}
                  sx={{
                    color: "#2C80FF",
                    display: "flex",
                    margin: "Auto",
                    marginTop: "15px"
                  }}
                />
              ) : (
                <>

                  {messageList && messageList.length && messageList.map((item) => (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1em",
                      }}
                    >
                      <Box
                        style={{
                          background: item.from_id === auth?.user?._id ? "#2593D6" : "#fff",
                          borderRadius: 24,
                          padding: "1em",
                          width: "auto",
                          marginLeft: item.from_id === auth?.user?._id ? "auto" : 0,
                          marginRight: item.from_id === auth?.user?._id ? 0 : "auto",
                          marginTop: "1em",
                          borderRadius:
                            item.from_id === auth?.user?._id
                              ? "24px 24px 0px 24px"
                              : "24px 24px 24px 0px",
                        }}
                      >
                        <Text
                          style={{
                            color: item.from_id === auth?.user?._id ? "#fff" : "#818181",
                            font: "normal normal normal 14px Poppins",
                            fontSize: "13px",
                          }}
                        >
                          {item.message}
                        </Text>
                      </Box>
                      <Text
                        style={{
                          color: "#818181",
                          font: "normal normal normal 14px Poppins",
                          fontSize: "10.5px",
                          marginLeft: item.from_id === auth?.user?._id ? "auto" : 0,
                        }}
                      >
                        {moment(item.createdAt).fromNow()}
                      </Text>
                    </Box>
                  ))}
                </>
              )}
            </Box>
            <Box
              sx={{
                background: "#2593D6",
                display: "flex",
                flexDirection: "row",
                padding: ".7em",
                mt: "1em",
                height: 44,
                borderRadius: 24,
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  width: 46,
                  height: 42,
                  cursor: "pointer",
                  mt: "auto",
                  mb: "auto",
                }}
              >
                <PlusThin fontSize={32} />
              </Box>
              <Box
                sx={{
                  ml: ".7em",
                  width: "100%",
                  background: "#fff",
                  borderRadius: "24px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      sendMessage(); // Call sendMessage function when Enter key is pressed
                    }
                  }}
                  placeholder="Type a message"
                  style={{
                    width: "95%",
                    background: "#fff",
                    borderRadius: 18,
                    paddingLeft: ".5em",
                  }}
                />
                <Box
                  sx={{
                    background: "#00476a",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                    cursor: "pointer",
                    mt: ".4em",
                    mr: ".5em",
                  }}
                >
                  <Send onClick={sendMessage}
                    fontSize={18}
                    color="#fff"
                    style={{ transform: "rotate(375deg)", marginTop: ".1em" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Template>
  );
}

export default Message;
