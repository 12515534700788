import axios from "../Utils/axios";
import { MessagesConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

// Assuming your getAdmins action creator is defined like this
export const getMessageChatList = (params) => {
    return async (dispatch) => {
        dispatch({ type: MessagesConstants.CHAT_LIST_REQUEST });

        try {
            const res = await axios.get(apiUrls.getChatList);

            if (res.status === 200) {
                dispatch({
                    type: MessagesConstants.CHAT_LIST_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: MessagesConstants.CHAT_LIST_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getChatHistory = (id, params) => {
    return async (dispatch) => {
        dispatch({ type: MessagesConstants.MESSAGE_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getChatListHistory}${id}`, { params });

            if (res.status === 200) {
                dispatch({
                    type: MessagesConstants.MESSAGE_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.data,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: MessagesConstants.MESSAGE_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const sendChatMessage = (id, params) => {
    return async (dispatch) => {
        dispatch({ type: MessagesConstants.SEND_MESSAGE_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.sendMessage}${id}`, params );

            if (res.status === 200) {
                dispatch({
                    type: MessagesConstants.SEND_MESSAGE_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.data,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: MessagesConstants.SEND_MESSAGE_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getSupportTokenList = () => {
    return async (dispatch) => {
        dispatch({ type: MessagesConstants.SUPPORT_TOKEN_LIST_REQUEST });

        try {
            const res = await axios.get(apiUrls.getalltickets);

            if (res.status === 200) {
                dispatch({
                    type: MessagesConstants.SUPPORT_TOKEN_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: MessagesConstants.SUPPORT_TOKEN_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getSupportTokenMessageByTokenId = (id) => {
    return async (dispatch) => {
        dispatch({ type: MessagesConstants.SUPPORT_TOKEN_MESSAGE_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getticketById}?ticketId=${id}`);

            if (res.status === 200) {
                dispatch({
                    type: MessagesConstants.SUPPORT_TOKEN_MESSAGE_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.data,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: MessagesConstants.SUPPORT_TOKEN_MESSAGE_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const sendSupportTokenMessageByTokenId = (id, body) => {
    return async (dispatch) => {
        dispatch({ type: MessagesConstants.SUPPORT_SEND_MESSAGE_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.supportTokenMessage}${id}`, body);

            if (res.status === 200) {
                dispatch({
                    type: MessagesConstants.SUPPORT_SEND_TOKEN_MESSAGE_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: MessagesConstants.SUPPORT_SEND_TOKEN_MESSAGE_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getSupportTokenDetail = (id) => {
    return async (dispatch) => {
        dispatch({ type: MessagesConstants.SUPPORT_DETAIL_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getTokenDetail}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: MessagesConstants.SUPPORT_DETAIL_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.data,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: MessagesConstants.SUPPORT_DETAIL_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

