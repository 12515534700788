import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Template from "../../Template";
import mokcup1 from "../..//Assets/Images/mokcup1.png";
import "./index.css";
import Input from "../../Components/Core/Input";
import Button from "../../Components/Core/Button";
import AlertBox from "../../Components/Alert";
import FourDigitInput from "../../Components/FourDigitInput";
import MailNegative from "../../Assets/Icons/Huge-icon-communication-outline-mail-negative.svg";
import Shield from "../../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import OutlineBook from "../../Assets/Icons/huge-icon-education-outline-book.svg";
import OutlineStack from "../../Assets/Icons/huge-icon-education-outline-cube-stack.svg";
// import backgroundImage from "../..//Assets/Images/shutterstock_1569440923.png";
import backgroundImage from "../../Assets/Images/password.png"
import verificationBackgoundImage from "../../Assets/Images/passVerification.png"
import setPassBackgroundImage from "../../Assets/Images/setPassword.png"
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { otpRequest, verifyOtp, resetPassword } from "../../Actions/auth";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";


const validationSchema = yup.object({
    email: yup.string().email("Email is invalid").required("Email is required")
});

const passwordValidationSchema = yup.object({
    password: yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    passwordConfirmation: yup.string().required('Confirm password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [boxType, setBoxType] = useState("email");
    const [isLoading, setIsLoading] = useState(false);
    const [Errors, setErrors] = useState(null);
    const [alert, setAlert] = useState({ show: false, type: "", message: "" });


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(755));

    const sendOtp = async () => {
        setIsLoading(true);

        const data = {
            email
        };

        try {
            await validationSchema.validate(data, {
                abortEarly: false,
            });
            dispatch(otpRequest(data))
                .then((result) => {
                    setIsLoading(false);
                    setBoxType("OTP");
                    setAlert({ show: true, type: 'success', message: "OTP sent successfully." });
                })
                .catch((err) => {
                    setIsLoading(false);
                    setAlert({ show: true, type: 'error', message: err?.response?.data?.error });
                });
        } catch (validationError) {
            const formattedErrors = {};
            validationError.inner.forEach((error) => {
                formattedErrors[error.path] = error.message;
            });

            setErrors(formattedErrors);
            setIsLoading(false);
        }
    };


    const verifyResetPasswordOtp = async () => {
        if (!otp || otp.length < 4) {
            setAlert({ show: true, type: 'warning', message: "Please enter the OTP" });
            return;
        }
        setIsLoading(true);

        const data = {
            email,
            otp
        };

        try {
            dispatch(verifyOtp(data))
                .then((result) => {
                    setIsLoading(false);
                    setBoxType("reset password");
                    setAlert({ show: true, type: 'success', message: "OTP verified successfully." });
                })
                .catch((err) => {
                    setIsLoading(false);
                    setAlert({ show: true, type: 'error', message: "Invalid Otp" });
                });
        } catch (validationError) {
            const formattedErrors = {};
            validationError.inner.forEach((error) => {
                formattedErrors[error.path] = error.message;
            });
            setErrors(formattedErrors);
            setIsLoading(false);
        }
    };

    const changePassword = async () => {
        setIsLoading(true);

        const data = {
            password,
            passwordConfirmation,
        };

        try {
            await passwordValidationSchema.validate(data, {
                abortEarly: false,
            });

            const value = {
                email,
                newPassword : password,
            }

            dispatch(resetPassword(value))
                .then((result) => {
                    setIsLoading(false);
                    setAlert({ show: true, type: 'success', message: "Password reset successfully." });
                    navigation('/login');
                })
                .catch((err) => {
                    setIsLoading(false);
                    setAlert({ show: true, type: 'error', message: err?.response.data?.error });
                });
        } catch (validationError) {
            const formattedErrors = {};
            validationError.inner.forEach((error) => {
                formattedErrors[error.path] = error.message;
            });
            setErrors(formattedErrors);
            setIsLoading(false);
        }
    };


    return (
        <Template
            showHeader={true}
            headerProps={{
                transparent: true,
                backgroundImage: boxType === "OTP" ? verificationBackgoundImage : boxType=="reset password"? setPassBackgroundImage:backgroundImage,

            }}
        >
            <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />
            <Box
                sx={{
                    mt: "-6em",
                    width: "88%",
                    ml: "auto",
                    mr: "auto",
                    display: isVerySmallScreen?" ":"flex",
                    flexDirection: "row",
                    position: "relative",
                    pb: "5em",
                }}
            >
                <img
                    alt="mokcup1"
                    src={mokcup1}
                    sx={{
                        width:isSmallScreen ?"20%":"30%" ,
                        width: isVerySmallScreen?"80%":"30%",
                    }}
                />
                <Box
                    sx={{
                        width: isVerySmallScreen?"90%":"25%" ,
                        mt: isVerySmallScreen ?"2em":"7.5em",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                width: 56,
                                height: 36,
                                backgroundColor: "#2C80FF",
                                borderRadius: "6px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mr: ".75em",
                                mt: ".5em",
                            }}
                        >
                            <img
                                alt="OutlineBook"
                                src={OutlineBook}
                                style={{
                                    width: "18px",
                                    height: "18px",
                                }}
                            />
                        </Box>
                        <Box>
                            <h3
                                style={{
                                    color: "#191A1C",
                                    font: "normal normal 600 15px Poppins",
                                    margin: "4px 0",
                                }}
                            >
                                Lorem Ipsum is simply dummy text of the printing
                            </h3>
                            <p
                                style={{
                                    font: "normal normal normal 10px/18px Poppins",
                                    color: "#474C4E",
                                }}
                            >
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry.
                            </p>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            mt: 2,
                        }}
                    >
                        <Box
                            sx={{
                                width: 56,
                                height: 36,
                                backgroundColor: "#2C80FF",
                                borderRadius: "6px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mr: ".75em",
                                mt: ".5em",
                            }}
                        >
                            <img
                                alt="MailNegative"
                                src={OutlineStack}
                                style={{
                                    width: "18px",
                                    height: "18px",
                                }}
                            />
                        </Box>
                        <Box>
                            <h3
                                style={{
                                    color: "#191A1C",
                                    font: "normal normal 600 15px Poppins",
                                    margin: "4px 0",
                                }}
                            >
                                Lorem Ipsum is simply dummy text of the printing
                            </h3>
                            <p
                                style={{
                                    font: "normal normal normal 10px/18px Poppins",
                                    color: "#474C4E",
                                }}
                            >
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry.
                            </p>
                        </Box>
                    </Box>
                </Box>
                {boxType === "email" && (
                    <Box className="login_Box"
                    sx={{ padding : isVerySmallScreen ?"1rem":"" }}
                    style={{
                      width: isVerySmallScreen?"80%":"25%",
                      marginTop :isVerySmallScreen?"5rem":"",
                       
                    }}
                    >
                        <h3>Password</h3>
                        <p>Forgot password? No worries.</p>
                        <Input
                            icon={
                                <img
                                    alt="MailNegative"
                                    src={MailNegative}
                                    style={{ width: "18px", height: "18px" }}
                                />
                            }
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your email"
                            type="email"
                            style={{ marginTop: "2em", marginBottom: "16px" }}
                            error={Errors?.email || ""}
                        />
                        <Button isLoading={isLoading} onClick={sendOtp}>
                            Send Verification Code
                        </Button>
                        <p
                            style={{
                                font: " normal normal normal 12px Poppins",
                                color: "#656a72",
                                marginTop: "2em",
                                fontSize: "12px !important",
                            }}
                        >
                            I think i remember my password.
                            <span
                                style={{
                                    color: "rgba(44, 128, 255, 1)",
                                    cursor: "pointer",
                                    fontWeight: "600",
                                }}
                                onClick={() => {
                                    window.location.href = "/login";
                                }}
                            >
                                Login
                            </span>
                        </p>
                    </Box>
                )}

                {boxType === "OTP" && (
                    <Box className="login_Box"
                    sx={{ padding : isVerySmallScreen ?"1rem":"" }}
                    style={{
                      width: isVerySmallScreen?"80%":"25%",
                      marginTop :isVerySmallScreen?"5rem":"",
                       
                    }}
                    >
                        <h3>Password</h3>
                        <p>We have sent verification code to your email.</p>
                        <FourDigitInput
                            value={otp}
                            onChange={(value) => setOtp(value)}
                        />
                        <Button isLoading={isLoading} onClick={verifyResetPasswordOtp}>
                            Submit
                        </Button>
                        <p
                            style={{
                                font: " normal normal normal 12px Poppins",
                                color: "#656a72",
                                marginTop: "2em",
                                fontSize: "12px !important",
                            }}
                        >
                            Didn’t receive the code?
                            <span
                                style={{
                                    color: "rgba(44, 128, 255, 1)",
                                    cursor: "pointer",
                                    fontWeight: "600",
                                }}
                                onClick={sendOtp}
                            >
                                Resend
                            </span>
                        </p>
                    </Box>
                )}

                {boxType === "reset password" && (
                    <Box className="login_Box"
                    sx={{ padding : isVerySmallScreen ?"1rem":"" }}
                    style={{
                      width: isVerySmallScreen?"80%":"25%",
                      marginTop :isVerySmallScreen?"5rem":"",
                       
                    }}
                    >
                        <h3>Password</h3>
                        <p>Set a new password.</p>
                        <Input
                            icon={
                                <img alt="shield" src={Shield} style={{ width: "18px", height: "18px" }} />
                            }
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            type={"password"}
                            style={{ margin: ".5em 0" }}
                            error={Errors?.password || ""}
                        />

                        <Input
                            icon={
                                <img alt="shield" src={Shield} style={{ width: "18px", height: "18px" }} />
                            }
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            placeholder="Confirm Password"
                            type={"password"}
                            style={{ margin: ".5em 0" }}
                            error={Errors?.passwordConfirmation || ""}
                        />
                        <Button isLoading={isLoading} onClick={changePassword}>
                            Set Password
                        </Button>
                    </Box>
                )}
            </Box>

        </Template>
    );
};

export default ForgotPassword;