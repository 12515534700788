export const authConstants = {
  OTP_REQUEST: "OTP_REQUEST",
  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_FAILURE: "OTP_FAILURE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCESS: "LOGOUT_SUCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCESS: "REGISTER_SUCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCESS: "UPDATE_USER_SUCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};

export const completeProfileConstants = {
  ADD_LICENCE_REQUEST: "ADD_LICENCE_REQUEST",
  ADD_LICENCE_SUCCESS: "ADD_LICENCE_SUCCESS",
  ADD_LICENCE_FAILURE: "ADD_LICENCE_FAILURE",
  ADD_COMPANY_REQUEST: "ADD_COMPANY_REQUEST",
  ADD_COMPANY_SUCCESS: "ADD_COMPANY_SUCCESS",
  ADD_COMPANY_FAILURE: "ADD_COMPANY_FAILURE",
  ADD_PERSONAL_REQUEST: "ADD_PERSONAL_REQUEST",
  ADD_PERSONAL_SUCCESS: "ADD_PERSONAL_SUCCESS",
  ADD_PERSONAL_FAILURE: "ADD_PERSONAL_FAILURE",
  ADD_COVERAGE_AREA_REQUEST: "ADD_COVERAGE_AREA_REQUEST",
  ADD_COVERAGE_AREA_SUCCESS: "ADD_COVERAGE_AREA_SUCCESS",
  ADD_COVERAGE_AREA_FAILURE: "ADD_COVERAGE_AREA_FAILURE",
  ADD_DIGITAL_REQUEST: "ADD_DIGITAL_REQUEST",
  ADD_DIGITAL_SUCCESS: "ADD_DIGITAL_SUCCESS",
  ADD_DIGITAL_FAILURE: "ADD_DIGITAL_FAILURE",
  ADD_BILLING_REQUEST: "ADD_BILLING_REQUEST",
  ADD_BILLING_SUCCESS: "ADD_BILLING_SUCCESS",
  ADD_BILLING_FAILURE: "ADD_BILLING_FAILURE",
  ADD_DOCUMENT_REQUEST: "ADD_DOCUMENT_REQUEST",
  ADD_DOCUMENT_SUCCESS: "ADD_DOCUMENT_SUCCESS",
  ADD_DOCUMENT_FAILURE: "ADD_DOCUMENT_FAILURE",
  VERIFY_AGREEMENT_REQUEST: "VERIFY_AGREEMENT_REQUEST",
  VERIFY_AGREEMENT_SUCCESS: "VERIFY_AGREEMENT_SUCCESS",
  VERIFY_AGREEMENT_FAILURE: "VERIFY_AGREEMENT_FAILURE",
  OTP_VERIFICATION_REQUEST: "OTP_VERIFICATION_REQUEST",
  OTP_VERIFICATION_SUCCESS: "OTP_VERIFICATION_SUCCESS",
  OTP_VERIFICATION_FAILURE: "OTP_VERIFICATION_FAILURE",
};

export const adminConstants = {
  ADMINS_REQUEST: "ADMINS_REQUEST",
  ADMINS_SUCCESS: "ADMINS_SUCCESS",
  ADMINS_FAILURE: "ADMINS_FAILURE",
  ADD_ADMIN_REQUEST: "ADD_ADMIN_REQUEST",
  ADD_ADMIN_SUCCESS: "ADD_ADMIN_SUCCESS",
  ADD_ADMIN_FAILURE: "ADD_ADMIN_FAILURE",
  ADMIN_DETAIL_REQUEST: "ADMIN_DETAIL_REQUEST",
  ADMIN_DETAIL_SUCCESS: "ADMIN_DETAIL_SUCCESS",
  ADMIN_DETAIL_FAILURE: "ADMIN_DETAIL_FAILURE",
};

export const accountConstants = {
  ACTIVATE_ACCOUNT_FAILURE: "ACTIVATE_ACCOUNT_FAILURE",
  ACTIVATE_ACCOUNT_REQUEST: "ACTIVATE_ACCOUNT_REQUEST",
  ACTIVATE_ACCOUNT_SUCCESS: "ACTIVATE_ACCOUNT_SUCCESS",
  SUSPEND_ACCOUNT_FAILURE: "SUSPEND_ACCOUNT_FAILURE",
  SUSPEND_ACCOUNT_REQUEST: "SUSPEND_ACCOUNT_REQUEST",
  SUSPEND_ACCOUNT_SUCCESS: "SUSPEND_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_FAILURE: "DELETE_ACCOUNT_FAILURE",
  DELETE_ACCOUNT_REQUEST: "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
};

export const stateConstants = {
  STATE_REQUEST: "STATE_REQUEST",
  STATE_SUCCESS: "STATE_SUCCESS",
  STATE_FAILURE: "STATE_FAILURE",
};

export const dashboardConstants = {
  ANALYTICS_REQUEST: "ANALYTICS_REQUEST",
  ANALYTICS_SUCCESS: "ANALYTICS_SUCCESS",
  ANALYTICS_FAILURE: "ANALYTICS_FAILURE",
  ORDER_HISTORY_REQUEST: "ORDER_HISTORY_REQUEST",
  ORDER_HISTORY_SUCCESS: "ORDER_HISTORY_SUCCESS",
  ORDER_HISTORY_FAILURE: "ORDER_HISTORY_FAILURE",
  REVISION_HISTORY_REQUEST: "REVISION_HISTORY_REQUEST",
  REVISION_HISTORY_SUCCESS: "REVISION_HISTORY_SUCCESS",
  REVISION_HISTORY_FAILURE: "REVISION_HISTORY_FAILURE",
  TOP_USER_REQUEST: "TOP_USER_REQUEST",
  TOP_USER_SUCCESS: "TOP_USER_SUCCESS",
  TOP_USER_FAILURE: "TOP_USER_FAILURE",
  TOP_CLIENT_REQUEST: "TOP_CLIENT_REQUEST",
  TOP_CLIENT_SUCCESS: "TOP_CLIENT_SUCCESS",
  TOP_CLIENT_FAILURE: "TOP_CLIENT_FAILURE",
};

export const usersConstants = {
  USERS_REQUEST: "USERS_REQUEST",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_FAILURE: "USERS_FAILURE",
  CLIENT_REQUEST: "CLIENT_REQUEST",
  CLIENT_SUCCESS: "CLIENT_SUCCESS",
  CLIENT_FAILURE: "CLIENT_FAILURE",
  USERS_COUNT_REQUEST: "USERS_COUNT_REQUEST",
  USERS_COUNT_SUCCESS: "USERS_COUNT_SUCCESS",
  USERS_COUNT_FAILURE: "USERS_COUNT_FAILURE",
  UNAPPROVED_USERS_REQUEST: "UNAPPROVED_USERS_REQUEST",
  UNAPPROVED_USERS_SUCCESS: "UNAPPROVED_USERS_SUCCESS",
  UNAPPROVED_USERS_FAILURE: "UNAPPROVED_USERS_FAILURE",
  USERS_DETAIL_REQUEST: "USERS_DETAIL_REQUEST",
  USERS_DETAIL_SUCCESS: "USERS_DETAIL_SUCCESS",
  USERS_DETAIL_FAILURE: "USERS_DETAIL_FAILURE",
  CLIENT_ORDER_REQUEST: "CLIENT_ORDER_REQUEST",
  CLIENT_ORDER_SUCCESS: "CLIENT_ORDER_SUCCESS",
  CLIENT_ORDER_FAILURE: "CLIENT_ORDER_FAILURE",
  CLIENT_PROPERTY_REQUEST: "CLIENT_PROPERTY_REQUEST",
  CLIENT_PROPERTY_SUCCESS: "CLIENT_PROPERTY_SUCCESS",
  CLIENT_PROPERTY_FAILURE: "CLIENT_PROPERTY_FAILURE",
};

export const MessagesConstants = {
  CHAT_LIST_REQUEST: "CHAT_LIST_REQUEST",
  CHAT_LIST_SUCCESS: "CHAT_LIST_SUCCESS",
  CHAT_LIST_FAILURE: "CHAT_LIST_FAILURE",
  MESSAGE_REQUEST: "MESSAGE_REQUEST",
  MESSAGE_SUCCESS: "MESSAGE_SUCCESS",
  MESSAGE_FAILURE: "MESSAGE_FAILURE",
  SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",
  SUPPORT_TOKEN_LIST_REQUEST: "SUPPORT_TOKEN_REQUEST",
  SUPPORT_TOKEN_SUCCESS: "SUPPORT_TOKEN_SUCCESS",
  SUPPORT_TOKEN_FAILURE: "SUPPORT_TOKEN_FAILURE",
  SUPPORT_TOKEN_MESSAGE_REQUEST: "SUPPORT_TOKEN_MESSAGE_REQUEST",
  SUPPORT_TOKEN_MESSAGE_SUCCESS: "SUPPORT_TOKEN_MESSAGE_SUCCESS",
  SUPPORT_TOKEN_MESSAGE_FAILURE: "SUPPORT_TOKEN_MESSAGE_FAILURE",
  SUPPORT_SEND_MESSAGE_REQUEST: "SUPPORT_SEND_TOKEN_MESSAGE_REQUEST",
  SUPPORT_SEND_TOKEN_MESSAGE_SUCCESS: "SUPPORT_SEND_TOKEN_MESSAGE_SUCCESS",
  SUPPORT_SEND_TOKEN_MESSAGE_FAILURE: "SUPPORT_SEND_TOKEN_MESSAGE_FAILURE",
  SUPPORT_DETAIL_REQUEST: "SUPPORT_DETAIL_REQUEST",
  SUPPORT_DETAIL_SUCCESS: "SUPPORT_DETAIL_SUCCESS",
  SUPPORT_DETAIL_FAILURE: "SUPPORT_DETAIL_FAILURE",
};

export const orderConstants = {
  MODIFY_ORDER_REQUEST: "MODIFY_ORDER_REQUEST",
  MODIFY_ORDER_SUCCESS: "MODIFY_ORDER_SUCCESS",
  MODIFY_ORDER_FAILURE: "MODIFY_ORDER_FAILURE",
  ASSIGNED_ORDER_REQUEST: "ASSIGNED_ORDER_REQUEST",
  ASSIGNED_ORDER_SUCCESS: "ASSIGNED_ORDER_SUCCESS",
  ASSIGNED_ORDER_FAILURE: "ASSIGNED_ORDER_FAILURE",
  COMPLETED_ORDER_REQUEST: "COMPLETED_ORDER_REQUEST",
  COMPLETED_ORDER_SUCCESS: "COMPLETED_ORDER_SUCCESS",
  COMPLETED_ORDER_FAILURE: "COMPLETED_ORDER_FAILURE",
  REVISION_ORDER_REQUEST: "REVISION_ORDER_REQUEST",
  REVISION_ORDER_SUCCESS: "REVISION_ORDER_SUCCESS",
  REVISION_ORDER_FAILURE: "REVISION_ORDER_FAILURE",
  CANCELLED_ORDER_REQUEST: "CANCELLED_ORDER_REQUEST",
  CANCELLED_ORDER_SUCCESS: "CANCELLED_ORDER_SUCCESS",
  CANCELLED_ORDER_FAILURE: "CANCELLED_ORDER_FAILURE",
};
