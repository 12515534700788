/* eslint-disable import/no-anonymous-default-export */
import { accountConstants } from "../Actions/constants";

const initState = {
    loading: false,
    error: null,
    message: "",
    user: null,
};

export default (state = initState, action) => {
    switch (action.type) {
        case accountConstants.SUSPEND_ACCOUNT_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case accountConstants.SUSPEND_ACCOUNT_SUCCESS:
            state = {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                loading: false,
            };
            break;
        case accountConstants.SUSPEND_ACCOUNT_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        case accountConstants.DELETE_ACCOUNT_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case accountConstants.DELETE_ACCOUNT_SUCCESS:
            state = {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                loading: false,
            };
            break;
        case accountConstants.DELETE_ACCOUNT_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        case accountConstants.ACTIVATE_ACCOUNT_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case accountConstants.ACTIVATE_ACCOUNT_SUCCESS:
            state = {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                loading: false,
            };
            break;
        case accountConstants.ACTIVATE_ACCOUNT_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        default:
    }
    return state;
};