import React from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./sidebar";

const index = ({
  children,
  showHeader = false,
  showFooter = true,
  showBackgroundColor = false,
  showSidebar = false,
  style,
  containerStyle,
  headerProps = {
    transparent: true,
    showLogin: false,
    showSearch: false,
    showNotification: false,
    showProfile: false,
    showsideMenu:false
  },
  sidebarProps = {
    page: "Dashboard"
  },

  footerProps = {},
}) => {
  const mergedStyles = {
    backgroundColor: showBackgroundColor ? "#F8FAFB" : "transparent",
    ...style,

  };

  return (
    <Box
      sx={
        !containerStyle
          ? {}
          : {
              backgroundImage: containerStyle.backgroundImage
                ? containerStyle.backgroundImage
                : "",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "100vh",
              backgroundColor: "#F8FAFB",
              backgroundPosition: "left -500px top -600px",
            }
      }
    >
      {showHeader && <Header {...headerProps} />}
      <Box sx={mergedStyles}>{children}</Box>
      {showFooter && <Footer {...footerProps} />}
      {showSidebar && <Sidebar {...sidebarProps} />}
    
    </Box>
  );
};

export default index;
