import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const BarGraph = ({ data, labels, title }) => {

    return (
        <div style={{
            display: 'grid',
            background: '#F2F2F2',
            borderRadius: '20px',
            margin: '10px'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '24px',
                marginTop: '16px'
            }}>
                <span style={{
                    color: 'black',
                    font: 'normal normal 600 18px/29px Poppins',
                    fontSize: '18px'
                }}>{title} History</span>
                <span style={{
                    color: 'black',
                    font: 'normal normal normal 12px/19px Poppins',
                    fontSize: '12px',
                    marginLeft: '10px',
                    marginTop: '7px'
                }}>Over Year</span>
            </div>
            {data && data.length && (
                <BarChart
                    width={500}
                    height={400}
                    series={[
                        { data: data, label: 'pv', id: 'pvId', color: "#349BDA", borderRadius: "8px" },
                    ]}
                    xAxis={[{ data: labels, scaleType: 'band' }]}
                />
            )}

        </div>
    );
};

export default BarGraph;