/* eslint-disable import/no-anonymous-default-export */
import { orderConstants } from "../Actions/constants";

const initState = {
    loading: false,
    error: null,
    orders: "",
    states: null,
};

export default (state = initState, action) => {
    switch (action.type) {
        case orderConstants.MODIFY_ORDER_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.MODIFY_ORDER_SUCCESS:
            state = {
                ...state,
                states: action.payload.data,
                orders: action.payload.orders,
                loading: false,
            };
            break;
        case orderConstants.MODIFY_ORDER_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        case orderConstants.ASSIGNED_ORDER_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.ASSIGNED_ORDER_SUCCESS:
            state = {
                ...state,
                states: action.payload.data,
                orders: action.payload.orders,
                loading: false,
            };
            break;
        case orderConstants.ASSIGNED_ORDER_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        case orderConstants.COMPLETED_ORDER_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.COMPLETED_ORDER_SUCCESS:
            state = {
                ...state,
                states: action.payload.data,
                orders: action.payload.orders,
                loading: false,
            };
            break;
        case orderConstants.COMPLETED_ORDER_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        case orderConstants.REVISION_ORDER_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.REVISION_ORDER_SUCCESS:
            state = {
                ...state,
                states: action.payload.data,
                orders: action.payload.orders,
                loading: false,
            };
            break;
        case orderConstants.REVISION_ORDER_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        case orderConstants.CANCELLED_ORDER_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.CANCELLED_ORDER_SUCCESS:
            state = {
                ...state,
                states: action.payload.data,
                orders: action.payload.orders,
                loading: false,
            };
            break;
        case orderConstants.CANCELLED_ORDER_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;

        default:
    }
    return state;
};