import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Alert from "@mui/material/Alert";
import React, { useEffect, useState } from 'react';
import "./index.css";

const AlertBox = ({ type, open, message, onChange }) => {
    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                setIsOpen(false);
                if (onChange) {
                    onChange(false);
                }
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isOpen, onChange]);

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                open={isOpen}
                autoHideDuration={2000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={() => setIsOpen(false)}
            >
                <Alert severity={type} variant="filled" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default AlertBox;
