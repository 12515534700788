import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import "./index.css";
import Icon from "../../Helpers/Icon";
import {motion} from 'framer-motion';



const Button = ({ icon, style, children, isLoading, isTransparent = false, ...props }) => {
  const mergedStyle = {
    background: isTransparent ? "transparent" : "rgba(44, 128, 255, 1) 0% 0% no-repeat padding-box",
    height: "40px",
    borderRadius: "11px",
    opacity: isLoading || props.disabled ? .6 : 1,
    ...style
  }
  return (
    <Box
    style={{boxShadow:"1px 2px 4px black"}}
      sx={mergedStyle}
    >
      <motion.button
      whileTap={{scale:0.9}}
      whileHover={{scale:1.1}}
        {...props}
        disabled={isLoading || props.disabled}
        style={{
          cursor: "pointer",
          border: isTransparent ? "2px solid #2D81FF" : "none",
          outline: "none",
          backgroundColor: "transparent",
          color: isTransparent ? "#2D81FF" : "rgba(255, 255, 255, 1)",
          font: "normal normal medium 14px/21px Poppins",
          fontWeight: "500",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          borderRadius: "11px",
          
        }}
      >
        {isLoading && (
          <CircularProgress
            size={16}
            sx={{
              color: "#fff",
              marginRight: "6px",
            }}
          />
        )}
        {icon && (
          <Icon
            name={icon}
            style={{
              width: "16px",
              height: "16px",
              marginRight: "7px"
            }}
          />
        )}
        <span
          style={style?.color ? { color: style.color } : {}}
        >{children}</span>
      </motion.button>
    </Box>
  );
};

export default Button;
