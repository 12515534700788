/* eslint-disable import/no-anonymous-default-export */
import { adminConstants } from "../Actions/constants";

const initState = {
  loading: false,
  error: null,
  message: "",
  admins: null,
};

export default (state = initState, action) => {
    switch (action.type) {
      case adminConstants.ADMINS_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case adminConstants.ADMINS_SUCCESS:
        state = {
          ...state,
          admins: action.payload.data,
          message: action.payload.message,
          loading: false,
        };
        break;
      case adminConstants.ADMINS_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
        break;
  
      default:
    }
    return state;
  };