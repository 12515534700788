import axios from "../Utils/axios";
import { stateConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

// Assuming your getAdmins action creator is defined like this
export const getStates = (params) => {
    return async (dispatch) => {
        dispatch({ type: stateConstants.STATE_REQUEST });

        try {
            const res = await axios.get(apiUrls.states, { params });

            if (res.status === 200) {
                dispatch({
                    type: stateConstants.STATE_SUCCESS,
                    payload: {
                        admin: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: stateConstants.STATE_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch states'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

