import { combineReducers } from "redux";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import stateReducer from "./stateReducer";
import accountReducer from "./accountReducer";
import orderReducer from "./orderReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  state: stateReducer,
  account: accountReducer,
  order: orderReducer
});

export default rootReducer;
