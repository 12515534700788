import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { Box } from "@mui/material";
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const BootstrapInput = styled(InputBase)(({ theme, isTransparent }) => ({
  background: isTransparent ? "transparent" : "#F2F2F2",
  padding: "8px 14px",
  height: "40px",
  borderRadius: "11px",
  display: "flex",
  border: isTransparent ? "1px solid #CCCCCC" : "none",
  flexDirection: "row",
  "& .MuiInputBase-input": {
    fontSize: 14,
    font: "normal normal medium 14px Poppins",
    color: "rgba(101, 106, 114, 1)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Poppins"',
    ].join(","),
    "&:focus": {
      background: "rgba(242, 242, 242, 1)",
    },
  },
}));

const SelectInput = ({
  label,
  value,
  name,
  options,
  onChange,
  disabled,
  placeholder,
  style,
  defaultValue,
  isTransparent,
  error,
  isOptional = false,
  haveNone = false,
  multiple = false
}) => {
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    onChange(personName)
  };
  return (
    <FormControl sx={style ? style : { width: "100%" }} variant="standard">
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {label && (
          <label
            style={{
              color: "#656A72",
              fontSize: "13px",
              marginBottom: ".4em",
              fontFamily: "Poppins",
            }}
          >
            {label}
          </label>
        )}
        {isOptional && (
          <label
            style={{
              color: "#B8B8B8",
              fontSize: "12px",
              marginBottom: ".4em",
              fontFamily: "Poppins",
              marginLeft: ".5em",
            }}
          >
            (Optional)
          </label>
        )}
      </Box>
      {multiple ? (
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<BootstrapInput isTransparent={isTransparent} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((name) => (
            <MenuItem
              key={name._id}
              value={name.name}
              style={getStyles(name.name, personName, theme)}
            >
              {name.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          name={name}
          disabled={disabled}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          placeholder={placeholder || "Select"}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          error={error}
          input={<BootstrapInput isTransparent={isTransparent} />}
        >
          <MenuItem value="" disabled>
            {placeholder || "Select"}
          </MenuItem>
          {haveNone && (
            <MenuItem value="">
              <em>Select</em>
            </MenuItem>
          )}
          {options.map((option, i) => (
            <MenuItem
              key={i}
              onClick={() => onChange(option.value)}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {error && (
        <p
          style={{
            color: "orangered",
            fontSize: ".7em",
          }}
          className="error"
        >
          {error}
        </p>
      )}
    </FormControl>
  );
};

export default SelectInput;
