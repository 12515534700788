import { Box } from "@mui/material";
import React from "react";
import "./index.css";
import Icon from "../Helpers/Icon";


const PopNotificaton = ({ handleClose }) => {

  return (
    <Box className="mainModal">
      <Box
        sx={{
          boxShadow: "0px 10px 20px #0000000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          width: "236px",
          zIndex: 1,
          ml: "auto",
          mt: "4.4%",
          mr: "5%",
          padding: "1em",
          position: 'relative',
        }}
      >
        <Icon
          onClick={() => handleClose()}
          name="Cancel"
          style={{
            width: "42px",
            height: "42px",
            position: "absolute",
            right: "4px",
            cursor: "pointer",
            top: "5px",
          }}
        />
     
        <div
          style={{
            width: "100%",
            height: "1.6px",
            backgroundColor: "#F2F2F2",
            margin: ".7em 0",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            m: ".6em .4em",
            fontSize:"0.8rem",
            background: "#F2F2F2 0% 0% no-repeat padding-box",
            padding:".8em",
            borderRadius:".9em"
          }}
        >
           <p>
           Lorem Ipsum is simply dummy text of the printing and typesetting
           industry.
           </p>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            m: ".6em .4em",
            fontSize:"0.8rem",
            background: "#F2F2F2 0% 0% no-repeat padding-box",
             padding:".8em",
            borderRadius:".9em"
          }}
        >
           <p>
           Lorem Ipsum is simply dummy text of the printing.
           </p>
        </Box>
      </Box>
    </Box>
  );
};

export default PopNotificaton;
