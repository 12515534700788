import { Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { clientHeader } from "../../Helpers/DataHelper";
import Icon from "../../Helpers/Icon";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../Components/Alert";
import Button from "../../Components/Core/Button";
import { getStates } from "../../Actions/state";
import Input from "../../Components/Core/Input";
import Select from "../../Components/Core/Select";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { getInitials, getStatus } from "../../Helpers/Constants";
import { clientInfoSchema, usrInfoSchema } from "../../Validations";
import ChangePassword from "../../Components/ChangePassword";
import EditMenu from "../../Components/EditMenu";

const AppraiserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { title, id } = location.state;

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cellPhone: "",
    workPhoneNumber: "",
    languageSpoken: "",
    address: "",
    state: "",
    city: "",
    zipCode: "",
    gender: "",
    race: "",
  });

  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyPhoneNumber:"",
    companyAddress1:"",
    companyAddress2:"",
    state:"",
    city:"",
    zipCode:""
    
  });

  const [states, setStates] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [zipcode, setZipCode] = useState([]);
  const [city, setCity] = useState([]);
  const [genders, setGenders] = useState([]);
  const [race, setRace] = useState([]);

  const auth = useSelector((state) => state.auth);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const handleChange = (field, value) => {
    setUserData((prevData) => {
      return {
        ...prevData,
        [field]: value,
      };
    });
  };

  if (title == undefined) {
    title = "State License(s)";
  }

  const goBack = () => {
    navigate("/detail", { state: { type: "Appraiser", id: id } });
  };

  return (
    <Template
      showHeader
      showSidebar={false}
      headerProps={{
        showSearch: true,
        showNotification: true,
        showProfile: true,
        showsideMenu: true,
      }}
      sidebarProps={{
        page: "User Management",
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <AlertBox
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onChange={(value) => {
          setAlert({ show: value, type: "", message: "" });
        }}
      />

      <Box
        sx={{
          background: "transparent",
          opacity: "1",
        }}
      >
        <EditMenu page="Appraiser" />

        <Box
          sx={{
            position: "relative",
            marginLeft: "330px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "40px",
              justifyContent: "flex-end",
              background: "transparent 0% 0% no-repeat padding-box",
              boxShadow: "15px 15px 50px #00000029",
              backdropFilter: "blur(50px)",
              WebkitBackdropFilter: "blur(50px)",
              height: "5rem",
            }}
          >
            <span
              onClick={goBack}
              style={{
                font: "normal normal 600 17px/28px Poppins",
                color: "#2C80FF",
                fontSize: "17px",
                marginRight: "64px",
                cursor: "pointer",
              }}
            >
              Back
            </span>
          </Box>

          {title === "State License(s)" ? (
            <>
              <h1>In state </h1>
            </>
          ) : (
            <></>
          )}

          {title === "Personal Information" ? (
            <>
              <Box
                style={{
                  font: "normal normal 600 16px/26px Poppins",
                  color: "#000000",
                  fontSize: "16px",
                  marginBottom: "20px",
                  marginLeft: "2em",
                }}
              >
                Personal Information
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "40px",
                  width: "90%",
                  marginTop: "1em",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  placeholder="First Name"
                  label="First Name"
                  type="text"
                  value={userData.firstName}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  // error={errors.firstName || ""}
                />

                <Input
                  placeholder="Last Name"
                  label="Last Name"
                  type="text"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  value={userData.lastName}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  // error={errors.lastName || ""}
                />

                <Input
                  placeholder="Email"
                  label="Email"
                  type="text"
                  style={{
                    width: "20%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                    cursor: "none",
                  }}
                  value={userData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  // error={errors.email || ""}
                />

                <Input
                  placeholder="Cell Phone"
                  label="Cell Phone"
                  type="text"
                  style={{
                    width: "20%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  value={userData.cellPhone}
                  onChange={(e) => handleChange("cellPhone", e.target.value)}
                  // error={errors.cellPhone || ""}
                />

                <Input
                  placeholder="Work Phone Number"
                  label="Phone Number"
                  type="number"
                  style={{
                    width: "20%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  value={userData.workPhoneNumber}
                  onChange={(e) =>
                    handleChange("workPhoneNumber", e.target.value)
                  }
                  // error={errors.cellPhone || ""}
                />

                <Select
                  placeholder="languages"
                  label="Language Spoken"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  options={languages}
                  value={userData.languageSpoken}
                  onChange={(option) => {
                    handleChange("languageSpoken", option);
                  }}
                  //   error={errors.state || ""}
                />

                <Input
                  placeholder="Street Address / PO Box"
                  label="Street Address / PO Box"
                  type="text"
                  value={userData.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  style={{
                    width: "20%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  // error={errors.firstName || ""}
                />

                <Select
                  placeholder="State"
                  label="State"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  options={states}
                  value={userData.state}
                  onChange={(option) => {
                    handleChange("state", option);
                  }}
                  //   error={errors.state || ""}
                />

                <Select
                  placeholder="city/town"
                  label="City / Town"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  options={city}
                  value={userData.city}
                  onChange={(option) => {
                    handleChange("city", option);
                  }}
                  //   error={errors.state || ""}
                />
                <Select
                  placeholder="Zip Code"
                  label="Zip Code"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "1em",
                  }}
                  options={zipcode}
                  value={userData.zipCode}
                  onChange={(option) => {
                    handleChange("zipCode", option);
                  }}
                  //   error={errors.state || ""}
                />
              </Box>

              <Box
                style={{
                  font: "normal normal 600 16px/26px Poppins",
                  color: "#000000",
                  fontSize: "16px",
                  marginBottom: "20px",
                  marginLeft: "2em",
                }}
              >
                Demographics
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "40px",
                  width: "90%",
                  marginTop: "1em",
                  flexWrap: "wrap",
                }}
              >
                <Select
                  placeholder="Gender"
                  label="Gender"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  options={genders}
                  value={userData.gender}
                  onChange={(option) => {
                    handleChange("gender", option);
                  }}
                  //   error={errors.state || ""}
                />

                <Select
                  placeholder="Race"
                  label="Race"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  options={race}
                  value={userData.race}
                  onChange={(option) => {
                    handleChange("race", option);
                  }}
                  //   error={errors.state || ""}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "40px",
                  width: "90%",
                  flexWrap: "wrap",
                  marginLeft: "2em",
                }}
              >
                <Button
                  // onClick={handleClose}
                  style={{
                    padding: ".2em 2em .2em 2em",
                  }}
                >
                  Update & Save
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}

          {title === "Company Information" ? (
            <>
                <Box
                style={{
                  font: "normal normal 600 16px/26px Poppins",
                  color: "#000000",
                  fontSize: "16px",
                  marginBottom: "20px",
                  marginLeft: "2em",
                }}
              >
                Company Information
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "40px",
                  width: "90%",
                  marginTop: "1em",
                  flexWrap: "wrap",
                }}
              >
                
                <Input
                  placeholder="Company Name"
                  label="Company Name"
                  type="text"
                  value={companyData.companyName}
                  onChange={(e) => handleChange("companyName", e.target.value)}
                  style={{
                    width: "30%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  // error={errors.firstName || ""}
                />

                <Input
                  placeholder="Company Phone Number"
                  label="Company Phone Number"
                  type="text"
                  style={{
                    width: "30%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  value={companyData.companyPhoneNumber}
                  onChange={(e) => handleChange("companyPhoneNumber", e.target.value)}
                  // error={errors.lastName || ""}
                />
                

                <Input
                  placeholder="Street Address / PO Box"
                  label="Company Address 1"
                  type="text"
                  style={{
                    width: "30%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                    cursor: "none",
                  }}
                  value={companyData.companyAddress1}
                  onChange={(e) => handleChange("companyAddress1", e.target.value)}
                  // error={errors.email || ""}
                />

                <Input
                  placeholder="Street Address / PO Box"
                  label="Company Address 2"
                  type="text"
                  style={{
                    width: "30%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  value={companyData.companyAddress2}
                  onChange={(e) => handleChange("companyAddress2", e.target.value)}
                  // error={errors.cellPhone || ""}
                />

  

                <Select
                  placeholder="State"
                  label="State"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  options={states}
                  value={companyData.state}
                  onChange={(option) => {
                    handleChange("state", option);
                  }}
                  //   error={errors.state || ""}
                />

<Select
                  placeholder="city/town"
                  label="City / Town"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "2em",
                  }}
                  options={city}
                  value={companyData.city}
                  onChange={(option) => {
                    handleChange("city", option);
                  }}
                  //   error={errors.state || ""}
                />

                


                <Select
                  placeholder="Zip Code"
                  label="Zip Code"
                  style={{
                    width: "15%",
                    marginLeft: "2em",
                    marginBottom: "1em",
                  }}
                  options={zipcode}
                  value={companyData.zipCode}
                  onChange={(option) => {
                    handleChange("zipCode", option);
                  }}
                  //   error={errors.state || ""}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "40px",
                  width: "90%",
                  flexWrap: "wrap",
                  marginLeft: "2em",
                }}
              >
                <Button
                  // onClick={handleClose}
                  style={{
                    padding: ".2em 2em .2em 2em",
                  }}
                >
                  Update & Save
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}

          {title === "Billing" ? (
            <>
              <h1>Billing</h1>
            </>
          ) : (
            <></>
          )}
          {title === "Coverage Area" ? (
            <>
              <h1>Coverage area</h1>
            </>
          ) : (
            <></>
          )}
          {title === "Documents" ? (
            <>
              <h1>Documents</h1>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Template>
  );
};

export default AppraiserDetail;
