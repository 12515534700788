import { authConstants } from "../Actions/constants";

const initState = {
  authenticate: false,
  authenticating: false,
  loading: false,
  error: null,
  message: "",
  user: null,
  token: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case authConstants.OTP_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.OTP_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        role: action.payload.role,
        message: action.payload.message,
        authenticate: false,
        authenticating: false,
        loading: false,
      };
      break;
    case authConstants.OTP_SUCCESS:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case authConstants.LOGIN_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.LOGIN_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        message: action.payload.message,
        authenticate: true,
        authenticating: false,
        loading: false,
      };
      break;
    case authConstants.LOGIN_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case authConstants.REGISTER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.REGISTER_SUCESS:
      state = {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        message: action.payload.message,
        authenticate: true,
        authenticating: false,
        loading: false,
      };
      break;
    case authConstants.REGISTER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case authConstants.LOGOUT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.LOGOUT_SUCESS:
      state = {
        ...initState,
      };
      break;
    case authConstants.LOGOUT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case authConstants.RESET_PASSWORD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        role: action.payload.role,
        message: action.payload.message,
        authenticate: false,
        authenticating: false,
        loading: false,
      };
      break;
    case authConstants.RESET_PASSWORD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    default:
  }
  return state;
};
